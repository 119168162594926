import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { SidenavReducer } from '@commons/sidenav'
import { ICostExtractState } from '@features/cost-extract/cost-extract-interfaces'
import { CostExtractReducer } from '@features/cost-extract'
import { IItemsExtractState } from '@features/items-extract/items-extract-interfaces'
import { ItemsExtractReducer } from '@features/items-extract'
import { IPreviewState } from '@features/preview/preview-interfaces'
import { PreviewReducer } from '@features/preview'
import { IFinishState } from '@features/finish/finish-interfaces'
import { PeriodHistoryReducer } from '@features/period-history'
import { FinishReducer } from '@features/finish'
import { DiscountPrioritizationReducer } from '@features/maintainers/discount-prioritization'
import { UserReducer, UserInterfaces } from '@commons/user'
import { IPeriodHistoryState } from '@features/period-history/period-history-interfaces'
import { IDiscountState } from '@features/maintainers/discount-prioritization/discount-prioritization-interfaces'
import { IFilterProvidersState } from '@features/filter-providers/filter-provider-interfaces'
import { FilterProvidersReducer } from '@features/filter-providers'
import { TransferSettlementsReducer } from '@features/transfers/transfer-settlements'
import { ProviderAlertReducer } from '@commons/providers-alert/index'
import { IProvidersAlertState } from '@commons/providers-alert/providers-alert-interfaces'
import {
  IErrorSendingState,
  ErrorSendingReducer
} from '@features/finish/elements/alerts/sending-error-alert/error-sending-reducer'
import { EditTemplateReducer } from '@features/maintainers/edit-template'
import { IEditTemplateState } from '@features/maintainers/edit-template/edit-template-interfaces'
import { ITransferSettlementsState } from '@features/transfers/transfer-settlements/transfer-settlements-interfaces'
import {
  IUnfinishedClosureAlert,
  UnfinishedClosureReducer
} from '@features/finish/elements/alerts/unfinished-closure-alert/unfinished-closure-reducer'
import { IItemsState } from '@features/maintainers/items/items-interface'
import ItemsReducer from '@features/maintainers/items/items-reducer'
import { ITransferHistoryState } from '@features/transfers/transfer-history/transfer-history-interfaces'
import TransferHistoryReducer from '@features/transfers/transfer-history/transfer-history-reducer'

const middleware = [thunk]

const allReducers = combineReducers({
  user: UserReducer.UserReducer,
  menu: SidenavReducer.SidenavReducer,
  costExtract: CostExtractReducer,
  itemsExtract: ItemsExtractReducer,
  preview: PreviewReducer,
  finish: FinishReducer,
  periodHistory: PeriodHistoryReducer,
  discountPrioritization: DiscountPrioritizationReducer,
  items: ItemsReducer,
  filterProviders: FilterProvidersReducer,
  providersAlert: ProviderAlertReducer,
  shippingErrorAlert: ErrorSendingReducer,
  unfinishedClosureAlert: UnfinishedClosureReducer,
  editTemplate: EditTemplateReducer,
  transferSettlements: TransferSettlementsReducer,
  transferHistory: TransferHistoryReducer
})

export interface IInitialRootState {
  user: UserInterfaces.IUserState
  menu: SidenavReducer.ISidenavState
  costExtract: ICostExtractState
  itemsExtract: IItemsExtractState
  preview: IPreviewState
  finish: IFinishState
  periodHistory: IPeriodHistoryState
  discountPrioritization: IDiscountState
  items: IItemsState
  filterProviders: IFilterProvidersState
  providersAlert: IProvidersAlertState
  shippingErrorAlert: IErrorSendingState
  unfinishedClosureAlert: IUnfinishedClosureAlert
  editTemplate: IEditTemplateState
  transferSettlements: ITransferSettlementsState
  transferHistory: ITransferHistoryState
}

const enhancers = []
const windowIfDefined = typeof window === 'undefined' ? null : (window as any)
if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
  enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__())
}

export const store = createStore(
  allReducers,
  compose(applyMiddleware(...middleware), ...enhancers)
)
