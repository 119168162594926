import 'react-toastify/dist/ReactToastify.css';
import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap-css-only/css/bootstrap.min.css'
import '@styles/main.scss'
import './i18n'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { store } from '@store/index'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer
        hideProgressBar={true}
        newestOnTop={true}
        autoClose={5000}
        position={'top-center'}
        className="noPrint"
        theme='colored'
      />
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
