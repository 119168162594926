export const routes = {
  login: '/login',
  costExtract: '/extraccion-costos',
  itemExtract: '/extraccion-items',
  preview: '/previsualizacion',
  finish: '/cierre',
  transfers: '/transferencias',
  transferSettlements: '/transferencias/transferir-liquidaciones',
  transferHistory: '/transferencias/historial-transferencias',
  periodHistory: '/historial-periodos',
  mantenedores: '/mantenedores',
  filterProviders: '/filtrar-proveedores',
  editTemplate: '/mantenedores/editar-plantilla',
  discountPrioritization: '/mantenedores/priorizacion-descuentos',
  items: '/mantenedores/items'
}

export const emptyList = [
  { zona: 'Centro', bases: [] },
  { zona: 'Norte', bases: [] },
  { zona: 'Sur', bases: [] }
]

export const zoneOrder = ['Centro', 'Norte', 'Sur']
