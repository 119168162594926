import {
  IAction,
  IBasesList,
  IBasesListOptions,
  IFilters
} from './preview-interfaces'

const FILL_PREVIEW_LIST = 'FILL_PREVIEW_LIST'
const FILL_BASES = 'FILL_BASES'
const FILL_BASES_OPTIONS = 'FILL_BASES_OPTIONS'
const FILL_FILTERS = 'FILL_FILTERS'
const SET_ZONE_SEL = 'SET_ZONE_SEL'
const SET_NAME_BASES_LIST = 'SET_NAME_BASES_LIST'
const GET_SUMMARY_ITEMS = 'GET_SUMMARY_ITEMS'
const SET_FETCHING = 'SET_FETCHING'

const fillPreviewList = (data: any): IAction => {
  return { type: FILL_PREVIEW_LIST, payload: data }
}

const fillBases = (data: Array<IBasesList>): IAction => {
  return { type: FILL_BASES, payload: data }
}

const fillBasesOptions = (data: Array<IBasesListOptions>): IAction => {
  return { type: FILL_BASES_OPTIONS, payload: data }
}

const fillFilters = (data: IFilters): IAction => {
  return { type: FILL_FILTERS, payload: data }
}

const setZoneSelected = (data: string): IAction => {
  return { type: SET_ZONE_SEL, payload: data }
}

const setNameBasesList = (data: any): IAction => {
  return { type: SET_NAME_BASES_LIST, payload: data }
}

const getSummaryItems = (data: any): IAction => {
  return { type: GET_SUMMARY_ITEMS, payload: data }
}

const setFetching = (data: boolean): IAction => {
  return { type: SET_FETCHING, payload: data }
}

export {
  FILL_PREVIEW_LIST,
  FILL_BASES,
  FILL_BASES_OPTIONS,
  FILL_FILTERS,
  SET_ZONE_SEL,
  SET_NAME_BASES_LIST,
  GET_SUMMARY_ITEMS,
  SET_FETCHING,
  fillPreviewList,
  fillBases,
  fillBasesOptions,
  fillFilters,
  setZoneSelected,
  setNameBasesList,
  getSummaryItems,
  setFetching
}
