import { AppThunk } from '@commons/thunk-types'
import { toast } from 'react-toastify'
import {
  setFetching,
  setSuccessModal,
  setTemplateStatus
} from './edit-template-actions'
import EditTemplateService from './edit-template-service'
import saveAs from 'file-saver'

const uploadFileHtml = (filesList: File[]): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    const params = {
      file: filesList
    }
    EditTemplateService.uploadFileHtml(params, getState().user.jwtoken).then(
      response => {
        if (response.status === 201) {
          dispatch(setFetching(false))
          toast.success('El template se subió exitósamente.')
        } else {
          dispatch(setFetching(false))
          toast.error(
            'Ocurrió un error al momento de subir el archivo por favor intente más tarde.'
          )
        }
      }
    )
  }
}

const downloadTemplateHtml = (): AppThunk => {
  return async (dispatch, getState) => {
    try {
      dispatch(setFetching(true))
      const result = await EditTemplateService.downloadTemplateHtml(
        getState().user.jwtoken
      )
      if (result.status === 200) {
        const blob = await result.blob()
        saveAs(blob, 'template.html')
        dispatch(setFetching(false))
        return
      }
      dispatch(setFetching(false))
    } catch (e) {
      dispatch(setFetching(false))
    }
  }
}

const getTemplateStatus = (): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    EditTemplateService.getTemplateStatus(getState().user.jwtoken)
      .then(response => {
        if (response.status === 200) {
          dispatch(setTemplateStatus(response.data))
        }
      })
      .catch(error => console.error('Error trying to get data', error))
      .finally(() => dispatch(setFetching(false)))
  }
}

const selectTemplate = (status: string): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    EditTemplateService.selectTemplate(status, getState().user.jwtoken)
      .then(response => {
        if (response.httpCode === 200) {
          dispatch(getTemplateStatus())
          dispatch(setSuccessModal(true))
        } else {
          toast.error('Ocurrió un error, por favor intente más tarde')
        }
      })
      .catch(error => console.error('Error trying to get data', error))
      .finally(() => {
        dispatch(setFetching(false))
      })
  }
}

export {
  uploadFileHtml,
  downloadTemplateHtml,
  selectTemplate,
  getTemplateStatus
}
