import {
  IAction,
  IActiveTab,
  IDisableItem,
  IItem,
  INewItem
} from './items-interface'

const singleKey = '@ITEMS'
const GET_LIST_ITEMS = `${singleKey}/GET_LIST_ITEMS`
const SET_FETCHING = `${singleKey}/SET_FETCHING`
const SHOW_CONFIRM_DISABLE = `${singleKey}/SHOW_CONFIRM_DISABLE`
const SHOW_CONFIRM_ENABLE = `${singleKey}/SHOW_CONFIRM_ENABLE`
const SHOW_CONFIRM_CREATE = `${singleKey}/SHOW_CONFIRM_CREATE`
const SET_DISABLE_ITEM = `${singleKey}/SET_DISABLE_ITEM`
const SET_ENABLE_ITEM = `${singleKey}/SET_ENABLE_ITEM`
const ADD_NEW_ITEM = `${singleKey}/ADD_NEW_ITEM`
const SET_ACTIVE_TAB = `${singleKey}/SET_ACTIVE_TAB`

const getListItems = (data: Array<IItem>): IAction => {
  return { type: GET_LIST_ITEMS, payload: data }
}

const setFetching = (data: boolean): IAction => {
  return { type: SET_FETCHING, payload: data }
}

const showConfirmDisable = (data: boolean): IAction => {
  return { type: SHOW_CONFIRM_DISABLE, payload: data }
}

const showConfirmEnable = (data: boolean): IAction => {
  return { type: SHOW_CONFIRM_ENABLE, payload: data }
}

const showConfirmCreate = (data: boolean): IAction => {
  return { type: SHOW_CONFIRM_CREATE, payload: data }
}

const setDisableItem = (data: IDisableItem): IAction => {
  return { type: SET_DISABLE_ITEM, payload: data }
}

const setEnableItem = (data: IDisableItem): IAction => {
  return { type: SET_ENABLE_ITEM, payload: data }
}

const addNewItem = (data: INewItem): IAction => {
  return { type: ADD_NEW_ITEM, payload: data }
}

const setActiveTab = (data: IActiveTab): IAction => {
  return { type: SET_ACTIVE_TAB, payload: data }
}

export {
  GET_LIST_ITEMS,
  SET_FETCHING,
  SHOW_CONFIRM_DISABLE,
  SHOW_CONFIRM_ENABLE,
  SET_DISABLE_ITEM,
  SET_ENABLE_ITEM,
  ADD_NEW_ITEM,
  SHOW_CONFIRM_CREATE,
  SET_ACTIVE_TAB,
  getListItems,
  setFetching,
  showConfirmEnable,
  showConfirmDisable,
  setDisableItem,
  setEnableItem,
  addNewItem,
  showConfirmCreate,
  setActiveTab
}
