const TOTAL_PROVIDERS = 'TOTAL_PROVIDERS'
const SHOW_WARNING = 'SHOW_WARNING'

const setTotalWarning = (data: number) => {
  return { type: TOTAL_PROVIDERS, payload: data }
}

const setShowWarning = (data: boolean) => {
  return { type: SHOW_WARNING, payload: data }
}

export { TOTAL_PROVIDERS, SHOW_WARNING, setTotalWarning, setShowWarning }
