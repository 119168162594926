import { UserActions } from '@commons/user'
import UserService from '@commons/user/user-service'
import { toast } from 'react-toastify'
import { decodeToken, setDataLocalStorage } from '@utilities/index'
import { updateWaitUserValidation } from './user-actions'
import { UserRoleEnum } from '@commons/role/role.enum'
import { AppThunk } from '@commons/thunk-types'

export const getUserValidation =
  (username: string, password: string): AppThunk =>
  async dispatch => {
    dispatch(updateWaitUserValidation(true))
    try {
      const data = {
        username: username,
        password: password,
        client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
        grant_type: 'password',
        client_secret: process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET
      }
      const result = await UserService.getUserValidation(data)
      if (result.httpCode === 200) {
        const decodeUserToken = decodeToken(result.data.access_token)
        const loggedUserData = JSON.parse(decodeUserToken)
        if (
          loggedUserData.realm_access.roles.filter(
            (rol: string) => rol === UserRoleEnum.ANALYST
          ).length < 1
        ) {
          dispatch(UserActions.updateJWToken(''))
          dispatch(UserActions.updateUserLogged(null))
          dispatch(UserActions.updateWaitUserValidation(false))
          return
        }
        setDataLocalStorage('jwt', result.data.access_token)
        dispatch(UserActions.updateJWToken(result.data.access_token))
        dispatch(UserActions.updateUserLogged(loggedUserData))
        dispatch(UserActions.updateWaitUserValidation(false))
        return
      } else if (result.httpCode === 401) {
        toast.info('Las credenciales son inválidas')
        dispatch(UserActions.updateWaitUserValidation(false))
      }
      dispatch(UserActions.updateJWToken(''))
      dispatch(UserActions.updateUserLogged(null))
      dispatch(UserActions.updateWaitUserValidation(false))
    } catch (e) {
      toast.info('No se realizó la operación. Intente nuevamente')
      dispatch(UserActions.updateJWToken(''))
      dispatch(UserActions.updateUserLogged(false))
      dispatch(UserActions.updateWaitUserValidation(false))
    }
  }

export const validateUserToken =
  (token: string): AppThunk =>
  async dispatch => {
    dispatch(updateWaitUserValidation(true))
    try {
      const result = await UserService.validateUserToken(token)
      if (result.httpCode === 200) {
        const decodeUserToken = decodeToken(token)
        const loggedUserData = JSON.parse(decodeUserToken)
        if (
          loggedUserData.realm_access.roles.filter(
            (rol: string) => rol === UserRoleEnum.ANALYST
          ).length < 1
        ) {
          dispatch(UserActions.updateJWToken(''))
          dispatch(UserActions.updateUserLogged(null))
          dispatch(UserActions.updateWaitUserValidation(false))
          return
        }
        setDataLocalStorage('jwt', token)
        dispatch(UserActions.updateJWToken(token))
        dispatch(UserActions.updateUserLogged(loggedUserData))
        dispatch(UserActions.updateWaitUserValidation(false))
      }
      dispatch(UserActions.updateWaitUserValidation(false))
    } catch (e) {
      setDataLocalStorage('jwt', '')
      dispatch(UserActions.updateJWToken(''))
      dispatch(UserActions.updateUserLogged(false))
      dispatch(UserActions.updateWaitUserValidation(false))
    }
  }

export const signOut = (): AppThunk => async dispatch => {
  dispatch(UserActions.updateWaitUserValidation(true))
  dispatch(UserActions.updateJWToken(''))
  dispatch(UserActions.updateUserLogged(null))
  dispatch(UserActions.updateUsername(''))
  setDataLocalStorage('jwt', '')
  dispatch(UserActions.updateWaitUserValidation(false))
}
