import {
  fillBases,
  fillBasesOptions,
  fillFilters,
  moveCosts,
  clearSearchFields,
  setDestinationProvider,
  setDestinationProviderId,
  setFetching,
  setSourceProvider,
  setSourceProviderId,
  setZoneSelected,
  showSuccessModal
} from './transfer-settlements-actions'
import TransferSettlementsService from './transfer-settlements-service'
import { initialState } from './transfer-settlements-reducer'
import { toast } from 'react-toastify'
import { AppThunk } from '@commons/thunk-types'

const fillFiltersData = (): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    TransferSettlementsService.getFilters(getState().user.jwtoken)
      .then(response => {
        if (response.status === 200) {
          dispatch(fillFilters({ ...response.data, base: '' }))
          dispatch(setFetching(false))
          return
        }
        dispatch(setFetching(false))
      })
      .catch(error => console.error('Error trying to get data', error))
  }
}

const fillBasesList = (): AppThunk => {
  return (dispatch, getState) => {
    TransferSettlementsService.getBases(getState().user.jwtoken)
      .then(response => {
        if (response.status === 200) {
          dispatch(fillBases(response.data))
        }
      })
      .catch(error => console.error('Error trying to get data', error))
  }
}

const searchSourceProvider = (rut: string): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    const { transferSettlements } = getState()
    TransferSettlementsService.getOriginProvider(
      { ...transferSettlements.filters },
      rut,
      getState().user.jwtoken
    )
      .then(response => {
        if (response.status === 200) {
          dispatch(setSourceProvider(response.data))
          return
        }
        if (response.status === 404) {
          dispatch(setSourceProvider(initialState.sourceProvider))
          dispatch(setZoneSelected(initialState.zoneSelected))
          dispatch(fillBasesOptions(initialState.basesListOptions))
          toast.info('No se encontraron resultados para esta búsqueda')
          return
        }
        dispatch(setDestinationProvider(initialState.destinationProvider))
        toast.error(
          'Ocurrió un error al momento de realizar la búsqueda por favor intente más tarde'
        )
      })
      .catch(error => console.error('Error trying to get data', error))
      .finally(() => dispatch(setFetching(false)))
  }
}

const searchDestinationProvider = (rut: string): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    const { transferSettlements } = getState()
    TransferSettlementsService.getDestinationProvider(
      { ...transferSettlements.filters },
      rut,
      getState().user.jwtoken
    )
      .then(response => {
        if (response.status === 200) {
          dispatch(setDestinationProvider(response.data))
          return
        }
        if (response.status === 404) {
          dispatch(setDestinationProvider(initialState.destinationProvider))
          toast.info('No se encontraron resultados para esta búsqueda')
          return
        }
        dispatch(setDestinationProvider(initialState.destinationProvider))
        toast.error(
          'Ocurrió un error al momento de realizar la búsqueda por favor intente más tarde'
        )
      })
      .catch(error => console.error('Error trying to get data', error))
      .finally(() => dispatch(setFetching(false)))
  }
}

const confirmSettlementTransfer = (user: string): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    const { transferSettlements } = getState()
    const transferData = {
      user,
      cuentaOrigen: transferSettlements.sourceProviderId,
      cuentaDestino: transferSettlements.destinationProviderId
    }
    TransferSettlementsService.transferSettlement(transferData, getState().user.jwtoken).then(
      response => {
        if (response.status === 201) {
          dispatch(setFetching(false))
          dispatch(cleanAllData())
          dispatch(showSuccessModal(true))
          return
        } else {
          dispatch(setFetching(false))
          toast.error(
            'Ocurrió un error al momento de realizar la transferencia por favor intente más tarde.'
          )
        }
      }
    )
  }
}

const cleanAllData = (): AppThunk => {
  return dispatch => {
    dispatch(clearSearchFields(true))
    dispatch(moveCosts(initialState.transferReceived))
    dispatch(setSourceProviderId(initialState.sourceProviderId))
    dispatch(setDestinationProviderId(initialState.destinationProviderId))
    dispatch(setSourceProvider(initialState.sourceProvider))
    dispatch(setDestinationProvider(initialState.destinationProvider))
    dispatch(setZoneSelected(initialState.zoneSelected))
    dispatch(fillBasesOptions(initialState.basesListOptions))
  }
}

export {
  fillBasesList,
  fillFiltersData,
  searchSourceProvider,
  searchDestinationProvider,
  confirmSettlementTransfer,
  cleanAllData
}
