import { IResponseService } from '@commons/commons-interfaces'
import RequestService from '@core/request-service'

export default class UserService extends RequestService {
  static getUserValidation(
    data: Record<string, any>
  ): Promise<IResponseService> {
    const endpoint = String(process.env.REACT_APP_KEYCLOAK_API_URL).concat(
      '/backoffice/protocol/openid-connect/token'
    )
    return super.postFormUrlEncoded(endpoint, data)
  }

  static validateUserToken(token: string): Promise<any> {
    const endpoint = String(process.env.REACT_APP_KEYCLOAK_API_URL).concat(
      '/backoffice/protocol/openid-connect/userinfo'
    )
    return super.postValidateUserToken(endpoint, token)
  }
}
