import BaseService from '@core/request-service'
import { createQueryString } from '@utilities/index'
import { IFilters } from './transfer-settlements-interfaces'

class TransferSettlementsService extends BaseService {
  static getFilters(jwt: string): Promise<any> {
    return super.get('/periodos/in-progress', jwt)
  }

  static getBases(jwt: string): Promise<{ status: number; data: any }> {
    return super.get('/bases', jwt)
  }

  static getOriginProvider(
    filters: IFilters,
    rut: string,
    jwt: string
  ): Promise<{ status: number; data: any }> {
    return super.get(
      `/liquidaciones/providers/account/origen?${createQueryString(
        filters
      )}&rut=${rut}`,
      jwt
    )
  }

  static getDestinationProvider(
    filters: IFilters,
    rut: string,
    jwt: string
  ): Promise<{ status: number; data: any }> {
    return super.get(
      `/liquidaciones/providers/account/destino?${createQueryString(
        filters
      )}&rut=${rut}`,
      jwt
    )
  }

  static transferSettlement(data: any, jwt:string): Promise<{ status: number; data: any }> {
    return super.postMethod(`/liquidaciones/transfers`, jwt, data)
  }
}

export default TransferSettlementsService
