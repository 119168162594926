import FinishService from './finish-service'
import {
  fillBases,
  fillBasesOptions,
  fillFilters,
  fillFinishList,
  setClosingStatus,
  setFetching,
  setNameBasesList,
  setZoneSelected
} from './finish-actions'
import mapOrder from '@utilities/map-order'
import { toast } from 'react-toastify'
import { IFilters } from './finish-interfaces'
import { setTotalWarning } from '@commons/providers-alert/providers-alert-actions'
import { initialState } from './finish-reducer'
import { fillSummaryData } from '@features/cost-extract/cost-extract-thunks'
import { emptyList, zoneOrder } from '@constants/index'
import { AppThunk } from '@commons/thunk-types'
import saveAs from 'file-saver'

const fillAllData = (): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    FinishService.getFilters(getState().user.jwtoken)
      .then(response => {
        if (response.status === 200) {
          const filters = response.data
          dispatch(fillFilters({ ...filters, base: '' }))
          dispatch(fillDataList({ summaryData: true }))
          dispatch(fetchClosingStatus())
          dispatch(getTotalWarning())
        } else {
          dispatch(setFetching(false))
        }
      })
      .catch(error => {
        dispatch(setFetching(false))
        console.error('Error trying to get data', error)
      })
  }
}

const fillDataList = ({ summaryData }: { summaryData: boolean }): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    const { finish } = getState()
    FinishService.getDetails({ ...finish.filters }, getState().user.jwtoken)
      .then(response => {
        if (response.status === 200) {
          dispatch(fillFinishList(mapOrder(response.data, zoneOrder, 'zona')))
          if (summaryData) {
            dispatch(fillSummaryData())
          }
        } else {
          const { base } = finish.filters
          dispatch(
            fillFinishList(mapOrder(base ? emptyList : [], zoneOrder, 'zona'))
          )
          dispatch(setFetching(false))
        }
      })
      .catch(error => {
        console.error('Error trying to get data', error)
        dispatch(setFetching(false))
      })
      .finally(() => {
        if (!summaryData) dispatch(setFetching(false))
      })
  }
}

const sendPeriodClose = (): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    const { finish } = getState()
    FinishService.sendPeriodClose(
      { ...finish.filters },
      getState().user.jwtoken
    ).then(response => {
      if (response.status === 202) {
        dispatch(setFetching(false))
        toast.info('Proceso de cierre iniciado')
        dispatch(fillDataList({ summaryData: false }))
        dispatch(fetchClosingStatus())
      } else if (response.status === 204) {
        dispatch(setFetching(false))
        toast.info(
          'No se logró cerrar el periodo, no se encontraron liquidaciones'
        )
      } else if (response.status === 422) {
        dispatch(setFetching(false))
        response.json().then((data: any) => {
          if (data && data.errorCode && data.errorCode === 1) {
            toast.info(
              'Se requiere que el porcentaje de aprobación sea igual a 100% para cerrar el periodo'
            )
          } else if (data && data.errorCode && data.errorCode === 2) {
            toast.info('Se requiere el número de HES para cerrar el periodo')
          }
        })
      } else if (response.status === 400) {
        dispatch(setFetching(false))
        toast.info('El periodo ya se encuentra cerrado')
      }
      dispatch(setFetching(false))
    })
  }
}

const fillBasesList = (): AppThunk => {
  return (dispatch, getState) => {
    FinishService.getBases(getState().user.jwtoken).then(response => {
      if (response.status === 200) {
        dispatch(fillBases(response.data))
      }
    })
  }
}

const fetchClosingStatus = (): AppThunk => {
  return (dispatch, getState) => {
    const { finish } = getState()
    FinishService.getClosingStatus(
      { ...finish.filters },
      getState().user.jwtoken
    ).then(response => {
      if (response.status === 200) {
        dispatch(setClosingStatus(response.data))
      }
    })
  }
}

const downloadHesFile = (): AppThunk => {
  return async (dispatch, getState) => {
    try {
      dispatch(setFetching(true))
      const { finish } = getState()
      const result = await FinishService.downloadHesTemplate(
        { ...finish.filters },
        getState().user.jwtoken
      )
      if (result.status === 200) {
        const blob = await result.blob()
        saveAs(blob, 'hes-liquidaciones-info.xlsx')
        dispatch(setFetching(false))
        return
      }
      dispatch(setFetching(false))
    } catch (e) {
      dispatch(setFetching(false))
    }
  }
}

const cleanFilters = (): AppThunk => {
  return dispatch => {
    const cleanData: IFilters = {
      mes: 0,
      anio: 0,
      base: ''
    }
    dispatch(fillFilters(cleanData))
  }
}

const getTotalWarning = (): AppThunk => {
  return (dispatch, getState) => {
    const { costExtract } = getState()
    FinishService.getTotalWarning(
      { ...costExtract.filters },
      getState().user.jwtoken
    ).then(response => {
      if (response.status === 200) {
        dispatch(setTotalWarning(response.data.totalWarning))
      } else {
        dispatch(setTotalWarning(0))
      }
    })
  }
}

const removeZoneFilters = (): AppThunk => {
  return (dispatch, getState) => {
    const {
      finish: { filters }
    } = getState()
    filters.base = ''
    dispatch(fillFilters(filters))
    dispatch(setZoneSelected(''))
    dispatch(setNameBasesList([]))
    dispatch(fillBasesOptions(initialState.basesListOptions))
  }
}

export {
  fillDataList,
  sendPeriodClose,
  fillBasesList,
  fillAllData,
  fetchClosingStatus,
  downloadHesFile,
  cleanFilters,
  getTotalWarning,
  removeZoneFilters
}
