import { IAction } from '@commons/commons-interfaces'
import {
  UPDATE_JWTOKEN,
  WAIT_USER_VALIDATION,
  UPDATE_USER_LOGGED,
  SET_USERNAME
} from './user-actions'
import { IUserState } from './user-interfaces'

export function UserReducer(state: IUserState, action: IAction): IUserState {
  state = state
    ? state
    : { jwtoken: '', userLogged: null, validating: true, username: '' }
  switch (action.type) {
    case UPDATE_JWTOKEN:
      return { ...state, jwtoken: String(action.payload) }
    case UPDATE_USER_LOGGED:
      return { ...state, userLogged: action.payload }
    case WAIT_USER_VALIDATION:
      return { ...state, validating: Boolean(action.payload) }
    case SET_USERNAME:
      return { ...state, username: action.payload }
    default:
      return state
  }
}
