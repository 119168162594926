import BaseService from '@core/request-service'
import { IFilters } from './discount-prioritization-interfaces'
import { createQueryString } from '@utilities/index'

class DiscountPrioritizationService extends BaseService {
  static getDiscountList(
    filters: IFilters,
    jwt: string
  ): Promise<{ status: number; data: any }> {
    return super.get(
      `/items/discount-priority?${createQueryString(filters)}`,
      jwt
    )
  }

  static getFilters(jwt: string): Promise<any> {
    return super.get('/periodos/in-progress', jwt)
  }

  static changeDiscountPrioritization(
    filters: IFilters,
    data: any,
    jwt: string
  ): Promise<any> {
    return super.put(
      `/items/discount-priority?${createQueryString(filters)}`,
      data,
      jwt
    )
  }
}

export default DiscountPrioritizationService
