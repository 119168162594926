import {
  fillDiscountList,
  fillFilters,
  setFetching,
  setSuccessModal
} from './discount-prioritization-actions'
import DiscountPrioritizationService from './discount-prioritization-service'
import { toast } from 'react-toastify'
import { AppThunk } from '@commons/thunk-types'

const getDataList = (): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    DiscountPrioritizationService.getFilters(getState().user.jwtoken)
      .then(response => {
        if (response.status === 200) {
          dispatch(fillFilters({ ...response.data, base: '' }))
          dispatch(getDiscountList())
        }
      })
      .catch(error => {
        dispatch(setFetching(false))
        console.error('Error trying to get data', error)
      })
  }
}

const getDiscountList = (): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    const { discountPrioritization } = getState()
    DiscountPrioritizationService.getDiscountList(
      { ...discountPrioritization.filters },
      getState().user.jwtoken
    )
      .then(response => {
        if (response.status === 200) {
          dispatch(fillDiscountList(response.data))
        } else {
          dispatch(fillDiscountList([]))
        }
      })
      .catch(error => {
        console.error('Error trying to get data', error)
      })
      .finally(() => {
        dispatch(setFetching(false))
      })
  }
}

const changeDiscountPrioritization = (data: any): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    const { discountPrioritization } = getState()
    DiscountPrioritizationService.changeDiscountPrioritization(
      discountPrioritization.filters,
      data,
      getState().user.jwtoken
    ).then(response => {
      if (response.httpCode === 200) {
        dispatch(fillDiscountList(response.data))
        dispatch(setSuccessModal(true))
        dispatch(setFetching(false))
      } else {
        dispatch(setFetching(false))
        toast.error('Algo salió mal, por favor intente más tarde')
      }
    })
  }
}

export { getDiscountList, getDataList, changeDiscountPrioritization }
