import '@fortawesome/fontawesome-free/css/all.min.css'
import React, { FC, memo, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { AiOutlineHome, AiOutlineSearch } from 'react-icons/ai'
import { BsFillCalendarFill, BsGear, BsArrowLeftRight } from 'react-icons/bs'
import { FaBars } from 'react-icons/fa'
import { MDBSideNavNav, MDBSideNav, MDBSideNavLink } from 'mdbreact'
import { IInitialRootState } from '@store/index'
import { SidenavActions } from '@commons/sidenav'

const Sidenav: FC = () => {
  const menuSelected = useSelector(
    (state: IInitialRootState) => state.menu.selected
  )
  const { t } = useTranslation()
  const [sideNavLeft, setSideNavLeft] = useState(false)
  const { pathname } = useLocation()
  const stepsConditions =
    menuSelected === '/extraccion-costos' ||
    menuSelected === '/extraccion-items' ||
    menuSelected === '/previsualizacion' ||
    menuSelected === '/cierre'
  const maintainerConditions =
    menuSelected === '/mantenedores/editar-plantilla' ||
    menuSelected === '/mantenedores/priorizacion-descuentos' ||
    menuSelected === '/mantenedores/items'
  const transferConditions =
    menuSelected === '/transferencias/transferir-liquidaciones' ||
    menuSelected === '/transferencias/historial-transferencias'

  const dispatch = useDispatch()
  const sidenavToggle = () => {
    setSideNavLeft(!sideNavLeft)
  }

  useEffect(() => {
    dispatch(SidenavActions.updateSelected(pathname))
  }, [dispatch, pathname])

  return (
    <>
      <button className="btn-menu" onClick={sidenavToggle}>
        <FaBars />
      </button>
      <MDBSideNav
        slim
        fixed
        triggerOpening={sideNavLeft}
        breakWidth={1279}
        className="sidenav-gradient"
      >
        <MDBSideNavNav
          className="py-5"
          toggleNavLabel={t('app.sidenav.minimize')}
        >
          <MDBSideNavLink
            to="/"
            className={`mb-4 ${stepsConditions && 'cyan-text'}`}
            topLevel={stepsConditions}
          >
            <AiOutlineHome className="mr-3 mb-1" size="2em" />
            <span className="side-nav-text">{t('app.sidenav.home')}</span>
          </MDBSideNavLink>
          <MDBSideNavLink
            to="/transferencias/transferir-liquidaciones"
            className={`mb-4 ${transferConditions && 'cyan-text active'}`}
            topLevel={transferConditions}
          >
            <BsArrowLeftRight className="mr-3 mb-1" size="2em" />
            <span className="side-nav-text">Transferir liquidaciones</span>
          </MDBSideNavLink>
          <MDBSideNavLink
            to="/historial-periodos"
            className={`mb-4 ${
              menuSelected === '/historial-periodos' && 'cyan-text'
            }`}
            topLevel={menuSelected === '/historial-periodos'}
          >
            <BsFillCalendarFill className="mr-3 mb-1" size="2em" />
            <span className="side-nav-text">Historial periodos</span>
          </MDBSideNavLink>
          <MDBSideNavLink
            to={'/filtrar-proveedores'}
            className={`mb-4 ${
              menuSelected === '/filtrar-proveedores' && 'cyan-text'
            }`}
            topLevel={menuSelected === '/filtrar-proveedores'}
            disabled={maintainerConditions}
          >
            <AiOutlineSearch className="mr-3 mb-1" size="2em" />
            <span className="side-nav-text">Buscar proveedor</span>
          </MDBSideNavLink>
          <MDBSideNavLink
            to={
              !maintainerConditions
                ? '/mantenedores/editar-plantilla'
                : menuSelected
            }
            className={`mb-4 ${maintainerConditions && 'cyan-text active'}`}
            topLevel={maintainerConditions}
            disabled={maintainerConditions}
          >
            <BsGear className="mr-3 mb-1" size="2em" />
            <span className="side-nav-text">Mantenedores</span>
          </MDBSideNavLink>
        </MDBSideNavNav>
      </MDBSideNav>
    </>
  )
}

export default memo(Sidenav)
