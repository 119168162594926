import {
  FILL_BASES,
  FILL_BASES_OPTIONS,
  FILL_FILTERS,
  FILL_FINISH_LIST,
  GET_CLOSING_STATUS,
  GET_SUMMARY_ITEMS,
  SET_FETCHING,
  SET_NAME_BASES_LIST,
  SET_ZONE_SEL,
  SHOW_MODAL_FINISH
} from './finish-actions'
import { IAction, IFinishState, IZone } from './finish-interfaces'

export const initialState: IFinishState = {
  fetching: false,
  zoneList: [],
  basesList: [],
  filters: {
    mes: 0,
    anio: 0,
    base: ''
  },
  basesListOptions: [{ text: 'Todas las bases', value: '' }],
  nameBasesList: [],
  zoneSelected: '',
  closingStatus: {
    inProgress: false,
    status: '',
    progressIndicator: 0,
    total: 0,
    current: 0,
    totalClosed: 0,
    totalNotSent: 0,
    totalPending: 0
  },
  summaryItems: [],
  showFinishModal: false
}

const FinishReducer = (state = initialState, action: IAction): IFinishState => {
  switch (action.type) {
    case FILL_FINISH_LIST:
      let payload = action.payload
      if (state.zoneSelected.length > 0) {
        payload = payload.filter(
          (item: IZone) => item.zona === state.zoneSelected
        )
      }
      return { ...state, zoneList: payload }
    case FILL_BASES:
      return { ...state, basesList: action.payload }
    case FILL_BASES_OPTIONS:
      return { ...state, basesListOptions: action.payload }
    case FILL_FILTERS:
      return { ...state, filters: action.payload }
    case SET_ZONE_SEL:
      return { ...state, zoneSelected: action.payload }
    case SET_NAME_BASES_LIST:
      return { ...state, nameBasesList: action.payload }
    case GET_SUMMARY_ITEMS:
      return { ...state, summaryItems: action.payload }
    case SET_FETCHING:
      return { ...state, fetching: action.payload }
    case GET_CLOSING_STATUS:
      return { ...state, closingStatus: action.payload }
    case SHOW_MODAL_FINISH:
      return { ...state, showFinishModal: action.payload }
    default:
      return state
  }
}

export default FinishReducer
