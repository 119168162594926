import PreviewService from './preview-service'
import {
  fillBases,
  fillBasesOptions,
  fillFilters,
  fillPreviewList,
  setNameBasesList,
  setZoneSelected
} from './preview-actions'
import mapOrder from '@utilities/map-order'
import { toast } from 'react-toastify'
import { setFetching } from '@features/preview/preview-actions'
import { setTotalWarning } from '@commons/providers-alert/providers-alert-actions'
import { initialState } from './preview-reducer'
import { fillSummaryData } from '@features/cost-extract/cost-extract-thunks'
import { emptyList, zoneOrder } from '@constants/index'
import { AppThunk } from '@commons/thunk-types'
import saveAs from 'file-saver'

const fillAllData = (): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    PreviewService.getFilters(getState().user.jwtoken)
      .then(response => {
        if (response.status === 200) {
          const filters = response.data
          dispatch(fillFilters({ ...filters, base: '' }))
          dispatch(fillDataList({ summaryData: true }))
          dispatch(getTotalWarning())
        } else {
          dispatch(setFetching(false))
        }
      })
      .catch(error => {
        dispatch(setFetching(false))
        console.error('Error trying to get data', error)
      })
  }
}

const fillDataList = ({ summaryData }: { summaryData: boolean }): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    const { preview } = getState()
    PreviewService.getDetails({ ...preview.filters }, getState().user.jwtoken)
      .then(response => {
        if (response.status === 200) {
          dispatch(fillPreviewList(mapOrder(response.data, zoneOrder, 'zona')))
          if (summaryData) {
            dispatch(fillSummaryData())
          }
        } else {
          const { base } = preview.filters
          dispatch(
            fillPreviewList(mapOrder(base ? emptyList : [], zoneOrder, 'zona'))
          )
          dispatch(setFetching(false))
        }
      })
      .catch(error => {
        console.error('Error trying to get data', error)
        dispatch(setFetching(false))
      })
      .finally(() => {
        if (!summaryData) dispatch(setFetching(false))
      })
  }
}

const sendPreviewFile = (filesList: File[]): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    const { preview } = getState()
    const params = {
      file: filesList[0]
    }
    PreviewService.sendPreviewFile(
      { ...preview.filters },
      params,
      getState().user.jwtoken
    )
      .then(response => {
        if (response.status === 200) {
          setTimeout(() => {
            toast.info('El archivo se subió correctamente')
            dispatch(setFetching(false))
          }, 2000)
        } else {
          toast.info('Ocurrió un inconveniente. Por favor intente nuevamente')
          dispatch(setFetching(false))
        }
      })
      .catch(error => {
        console.error('Error trying to get data', error)
      })
  }
}

const downloadPreviewTemplate = (): AppThunk => {
  return async (dispatch, getState) => {
    try {
      dispatch(setFetching(true))
      const { preview } = getState()
      const result = await PreviewService.downloadPreviewTemplate(
        {
          ...preview.filters
        },
        getState().user.jwtoken
      )
      if (result.status === 200) {
        const blob = await result.blob()
        saveAs(blob, 'carga-hes-liquidaciones-template.xlsx')
        dispatch(setFetching(false))
        return
      }
      dispatch(setFetching(false))
    } catch (e) {
      dispatch(setFetching(false))
    }
  }
}

const downloadPreviewInfo = (): AppThunk => {
  return async (dispatch, getState) => {
    try {
      dispatch(setFetching(true))
      const { preview } = getState()
      const result = await PreviewService.downloadPreviewInfo(
        { ...preview.filters },
        getState().user.jwtoken
      )
      if (result.status === 200) {
        const blob = await result.blob()
        saveAs(blob, 'carga-hes-liquidaciones-info.xlsx')
        dispatch(setFetching(false))
        return
      }
      dispatch(setFetching(false))
    } catch (e) {
      dispatch(setFetching(false))
    }
  }
}

const fillBasesList = (): AppThunk => {
  return (dispatch, getState) => {
    PreviewService.getBases(getState().user.jwtoken)
      .then(response => {
        if (response.status === 200) {
          dispatch(fillBases(response.data))
        }
      })
      .catch(error => console.error('Error trying to get data', error))
  }
}

const getTotalWarning = (): AppThunk => {
  return (dispatch, getState) => {
    const { costExtract } = getState()
    PreviewService.getTotalWarning(
      { ...costExtract.filters },
      getState().user.jwtoken
    )
      .then(response => {
        if (response.status === 200)
          dispatch(setTotalWarning(response.data.totalWarning))
        else dispatch(setTotalWarning(0))
      })
      .catch(error => console.error('Error trying to get data', error))
  }
}

const removeZoneFilters = (): AppThunk => {
  return (dispatch, getState) => {
    const {
      preview: { filters }
    } = getState()
    filters.base = ''
    dispatch(fillFilters(filters))
    dispatch(setZoneSelected(''))
    dispatch(setNameBasesList([]))
    dispatch(fillBasesOptions(initialState.basesListOptions))
  }
}

export {
  fillDataList,
  sendPreviewFile,
  downloadPreviewTemplate,
  downloadPreviewInfo,
  fillBasesList,
  fillAllData,
  getTotalWarning,
  removeZoneFilters
}
