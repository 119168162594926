import {
  IAction,
  IBasesList,
  IBasesListOptions,
  IFilters,
  IItemsDetails,
  IProvider
} from './transfer-settlements-interfaces'

const singleKey = '@TRANSFER'

const FILL_FILTERS = `${singleKey}/FILL_FILTERS`
const FILL_BASES = `${singleKey}/FILL_BASES`
const FILL_BASES_OPT = `${singleKey}/FILL_BASES_OPT`
const SET_ZONE_SEL = `${singleKey}/SET_ZONE_SEL`
const SET_FETCHING = `${singleKey}/SET_FETCHING`
const SET_SRC_PROVIDER = `${singleKey}/SET_SRC_PROVIDER`
const SET_DESTN_PROVIDER = `${singleKey}/SET_DESTN_PROVIDER`
const MOVE_COSTS = `${singleKey}/MOVE_COSTS`
const SET_SOURCE_ID = `${singleKey}/SET_SOURCE_ID`
const SET_DESTN_ID = `${singleKey}/SET_DESTN_ID`
const SHOW_CONFIRM_MODAL = `${singleKey}/SHOW_CONFIRM_MODAL`
const SHOW_SUCCESS_MODAL = `${singleKey}/SHOW_SUCCESS_MODAL`
const CLEAR_SEARCH_FIELDS = `${singleKey}/CLEAR_SEARCH_FIELDS`

const fillFilters = (data: IFilters): IAction => {
  return { type: FILL_FILTERS, payload: data }
}

const fillBases = (data: Array<IBasesList>): IAction => {
  return { type: FILL_BASES, payload: data }
}

const fillBasesOptions = (data: Array<IBasesListOptions>): IAction => {
  return { type: FILL_BASES_OPT, payload: data }
}

const setZoneSelected = (data: string): IAction => {
  return { type: SET_ZONE_SEL, payload: data }
}

const setFetching = (data: boolean): IAction => {
  return { type: SET_FETCHING, payload: data }
}

const setSourceProvider = (data: IProvider): IAction => {
  return { type: SET_SRC_PROVIDER, payload: data }
}

const setDestinationProvider = (data: IProvider): IAction => {
  return { type: SET_DESTN_PROVIDER, payload: data }
}

const moveCosts = (data: Array<IItemsDetails>): IAction => {
  return { type: MOVE_COSTS, payload: data }
}

const setSourceProviderId = (data: string): IAction => {
  return { type: SET_SOURCE_ID, payload: data }
}

const setDestinationProviderId = (data: string): IAction => {
  return { type: SET_DESTN_ID, payload: data }
}

const showConfirmationModal = (data: boolean): IAction => {
  return { type: SHOW_CONFIRM_MODAL, payload: data }
}

const showSuccessModal = (data: boolean): IAction => {
  return { type: SHOW_SUCCESS_MODAL, payload: data }
}

const clearSearchFields = (data: boolean): IAction => {
  return { type: CLEAR_SEARCH_FIELDS, payload: data }
}

export {
  FILL_FILTERS,
  FILL_BASES,
  FILL_BASES_OPT,
  SET_ZONE_SEL,
  SET_FETCHING,
  SET_SRC_PROVIDER,
  SET_DESTN_PROVIDER,
  MOVE_COSTS,
  SET_SOURCE_ID,
  SET_DESTN_ID,
  SHOW_CONFIRM_MODAL,
  SHOW_SUCCESS_MODAL,
  CLEAR_SEARCH_FIELDS,
  fillFilters,
  fillBases,
  fillBasesOptions,
  setZoneSelected,
  setFetching,
  setSourceProvider,
  setDestinationProvider,
  moveCosts,
  setSourceProviderId,
  setDestinationProviderId,
  showConfirmationModal,
  showSuccessModal,
  clearSearchFields
}
