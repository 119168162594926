import './processing.scss'
import React, { FC } from 'react'
import { MDBSpinner } from 'mdbreact'

interface IProps {
  show: boolean
}

const Processing: FC<IProps> = ({ show = false }) => {
  return (
    <>
      {show && (
        <div className="processing">
          <div className="text-center vertical-center w-100">
            <MDBSpinner big />
          </div>
        </div>
      )}
    </>
  )
}

export default Processing
