import { IAction } from './edit-template-interfaces'

const singleKey = '@TEMPLATE'
const SET_FETCHING = `${singleKey}/SET_FETCHING`
const SET_SUCCESS_MODAL = `${singleKey}/SET_SUCCESS_MODAL`
const SET_TEMPLATE_STATUS = `${singleKey}/SET_TEMPLATE_STATUS`

const setFetching = (data: boolean): IAction => {
  return { type: SET_FETCHING, payload: data }
}

const setSuccessModal = (data: boolean): IAction => {
  return { type: SET_SUCCESS_MODAL, payload: data }
}

const setTemplateStatus = (data: any): IAction => {
  return { type: SET_TEMPLATE_STATUS, payload: data }
}

export {
  SET_FETCHING,
  SET_SUCCESS_MODAL,
  SET_TEMPLATE_STATUS,
  setSuccessModal,
  setFetching,
  setTemplateStatus
}
