import { SHOW_UNFINISHED_ClOSURE } from './error-sending-actions'

export interface IUnfinishedClosureAlert {
  showAlert: boolean
}

type Actions = { type: string; payload: string }

const initialState = {
  showAlert: true
}

const UnfinishedClosureReducer = (state = initialState, action: Actions) => {
  if (action.type === SHOW_UNFINISHED_ClOSURE) {
    return { ...state, showAlert: action.payload }
  } else {
    return state
  }
}

export { UnfinishedClosureReducer }
