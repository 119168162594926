import ItemsExtractService from './items-extract-service'
import {
  fillListItems,
  fillBases,
  fillFilters,
  setFetching,
  setZoneSelected,
  setNameBasesList,
  fillBasesOptions
} from './items-extract-actions'
import mapOrder from '@utilities/map-order'
import { saveAs } from 'file-saver'
import { toast } from 'react-toastify'
import { setTotalWarning } from '@commons/providers-alert/providers-alert-actions'
import { initialState } from './items-extract-reducer'
import { emptyList, zoneOrder } from 'src/constants'
import { fillSummaryData } from '@features/cost-extract/cost-extract-thunks'
import { AppThunk } from '@commons/thunk-types'

const fillAllData = (): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    ItemsExtractService.getFilters(getState().user.jwtoken)
      .then(response => {
        if (response.status === 200) {
          const filters = response.data
          dispatch(fillFilters({ ...filters, base: '' }))
          dispatch(fillDataList({ summaryData: true }))
          dispatch(getTotalWarning())
        } else {
          dispatch(setFetching(false))
        }
      })
      .catch(error => {
        dispatch(setFetching(false))
        console.error('Error trying to get data', error)
      })
  }
}

const fillDataList = ({ summaryData }: { summaryData: boolean }): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    const { itemsExtract } = getState()
    ItemsExtractService.getDetails(
      { ...itemsExtract.filters },
      getState().user.jwtoken
    )
      .then(response => {
        if (response.status === 200) {
          dispatch(fillListItems(mapOrder(response.data, zoneOrder, 'zona')))
          if (summaryData) {
            dispatch(fillSummaryData())
          }
        } else {
          const { base } = itemsExtract.filters
          dispatch(
            fillListItems(mapOrder(base ? emptyList : [], zoneOrder, 'zona'))
          )
          dispatch(setFetching(false))
        }
      })
      .catch(error => {
        console.error('Error trying to get data', error)
        dispatch(setFetching(false))
      })
      .finally(() => {
        if (!summaryData) dispatch(setFetching(false))
      })
  }
}

const sendFileSummaryItems = (filesList: File[]): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    const { itemsExtract } = getState()
    const params = {
      file: filesList[0]
    }
    ItemsExtractService.sendFileSummaryItems(
      { ...itemsExtract.filters },
      params,
      getState().user.jwtoken
    ).then(response => {
      if (response.status === 200) {
        setTimeout(() => {
          toast.info('El archivo se subió correctamente')
          dispatch(fillDataList({ summaryData: true }))
        }, 3000)
      } else {
        toast.info('Ocurrió un inconveniente. Por favor intente nuevamente')
        dispatch(setFetching(false))
      }
    })
  }
}

const sendFiledd = (filesList: File[]): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    const { itemsExtract } = getState()
    const params = {
      file: filesList[0]
    }
    ItemsExtractService.sendFiledd(
      { ...itemsExtract.filters },
      params,
      getState().user.jwtoken
    ).then(response => {
      if (response.status === 200) {
        setTimeout(() => {
          toast.info('El archivo se subió correctamente')
          dispatch(fillDataList({ summaryData: true }))
        }, 20000)
      } else {
        toast.info('Ocurrió un inconveniente. Por favor intente nuevamente')
        dispatch(setFetching(false))
      }
    })
  }
}

const downloadTemplateItems = (): AppThunk => {
  return async (dispatch, getState) => {
    try {
      dispatch(setFetching(true))
      const result = await ItemsExtractService.downloadTemplateItems(
        getState().user.jwtoken
      )
      if (result.status === 200) {
        const blob = await result.blob()
        saveAs(blob, 'carga-resumen-items-template.xlsx')
        dispatch(setFetching(false))
        return
      }
      dispatch(setFetching(false))
    } catch (e) {
      dispatch(setFetching(false))
    }
  }
}

const downloadTemplatedd = (): AppThunk => {
  return async (dispatch, getState) => {
    try {
      dispatch(setFetching(true))
      const result = await ItemsExtractService.downloadTemplatedd(
        getState().user.jwtoken
      )
      if (result.status === 200) {
        const blob = await result.blob()
        saveAs(blob, 'carga-items-dd-template.xlsx')
        dispatch(setFetching(false))
        return
      }
      dispatch(setFetching(false))
    } catch (e) {
      dispatch(setFetching(false))
    }
  }
}

const fillBasesList = (): AppThunk => {
  return (dispatch, getState) => {
    ItemsExtractService.getBases(getState().user.jwtoken).then(response => {
      if (response.status === 200) {
        dispatch(fillBases(response.data))
      }
    })
  }
}

const getTotalWarning = (): AppThunk => {
  return (dispatch, getState) => {
    const { costExtract } = getState()
    ItemsExtractService.getTotalWarning(
      { ...costExtract.filters },
      getState().user.jwtoken
    ).then(response => {
      if (response.status === 200) {
        dispatch(setTotalWarning(response.data.totalWarning))
      }
    })
  }
}

const removeZoneFilters = (): AppThunk => {
  return (dispatch, getState) => {
    const {
      itemsExtract: { filters }
    } = getState()
    filters.base = ''
    dispatch(fillFilters(filters))
    dispatch(setZoneSelected(''))
    dispatch(setNameBasesList([]))
    dispatch(fillBasesOptions(initialState.basesListOptions))
  }
}

export {
  fillDataList,
  sendFileSummaryItems,
  downloadTemplateItems,
  downloadTemplatedd,
  fillBasesList,
  fillAllData,
  sendFiledd,
  getTotalWarning,
  removeZoneFilters
}
