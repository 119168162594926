import React from 'react'
import { MDBContainer } from 'mdbreact'
import logoBlue from '@assets/images/logo-blue.svg'
import './wide-loader.scss'

const WideLoader = () => {
  return (
    <MDBContainer className="wide-loader">
      <img className="logo-blue" src={logoBlue} alt="" />
    </MDBContainer>
  )
}

export default WideLoader
