import {
  FILL_BASES,
  FILL_BASES_OPT,
  FILL_FILTERS,
  MOVE_COSTS,
  SET_FETCHING,
  SET_SRC_PROVIDER,
  SET_DESTN_PROVIDER,
  SET_ZONE_SEL,
  SET_SOURCE_ID,
  SET_DESTN_ID,
  SHOW_SUCCESS_MODAL,
  SHOW_CONFIRM_MODAL,
  CLEAR_SEARCH_FIELDS
} from './transfer-settlements-actions'
import {
  IAction,
  ITransferSettlementsState
} from './transfer-settlements-interfaces'

export const initialState: ITransferSettlementsState = {
  fetching: false,
  basesList: [],
  filters: {
    mes: 0,
    anio: 0,
    base: ''
  },
  basesListOptions: [{ text: 'Todas las bases', value: '' }],
  zoneSelected: '',
  sourceProvider: {
    liquidacion: '',
    isAvailable: true,
    rut: '',
    nombre: '',
    email: '',
    base: '',
    totalLiquidar: 0,
    descuentoAplicado: 0,
    montoPendiente: 0,
    detallePorItem: []
  },
  destinationProvider: {
    liquidacion: '',
    isAvailable: true,
    rut: '',
    nombre: '',
    email: '',
    base: '',
    totalLiquidar: 0,
    descuentoAplicado: 0,
    montoPendiente: 0,
    detallePorItem: []
  },
  destinationRut: '',
  transferReceived: [],
  sourceProviderId: '',
  destinationProviderId: '',
  showConfirmationModal: false,
  showSuccessModal: false,
  removeSearchFieldText: false
}

const TransferSettlementsReducer = (
  state = initialState,
  action: IAction
): ITransferSettlementsState => {
  switch (action.type) {
    case FILL_FILTERS:
      return { ...state, filters: action.payload }
    case FILL_BASES:
      return { ...state, basesList: action.payload }
    case FILL_BASES_OPT:
      return { ...state, basesListOptions: action.payload }
    case SET_ZONE_SEL:
      return { ...state, zoneSelected: action.payload }
    case SET_FETCHING:
      return { ...state, fetching: action.payload }
    case SET_SRC_PROVIDER:
      return { ...state, sourceProvider: action.payload }
    case SET_DESTN_PROVIDER:
      return { ...state, destinationProvider: action.payload }
    case MOVE_COSTS:
      return { ...state, transferReceived: action.payload }
    case SET_SOURCE_ID:
      return { ...state, sourceProviderId: action.payload }
    case SET_DESTN_ID:
      return { ...state, destinationProviderId: action.payload }
    case SHOW_CONFIRM_MODAL:
      return { ...state, showConfirmationModal: action.payload }
    case SHOW_SUCCESS_MODAL:
      return { ...state, showSuccessModal: action.payload }
    case CLEAR_SEARCH_FIELDS:
      return { ...state, removeSearchFieldText: action.payload }
    default:
      return state
  }
}

export default TransferSettlementsReducer
