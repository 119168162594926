import BaseService from '@core/request-service'
import { IFilters } from './filter-provider-interfaces'
import { createQueryString } from '@utilities/index'

class FilterProvidersService extends BaseService {
  static getProviders(
    filters: IFilters,
    searchText: string,
    jwt: string
  ): Promise<{ status: number; data: any }> {
    return super.get(
      `/liquidaciones/providers?${createQueryString(
        filters
      )}&texto=${searchText}`,
      jwt
    )
  }

  static getPeriodHistory(jwt: string): Promise<any> {
    return super.get(`/periodos?state=Closed&limit=${process.env.REACT_APP_PERIOD_FILTER}`, jwt)
  }

  static getProviderDetails(
    filters: IFilters,
    rut: string,
    jwt: string
  ): Promise<{ status: number; data: any }> {
    return super.get(
      `/liquidaciones/close?${createQueryString(filters)}&rut=${rut}`,
      jwt
    )
  }

  static getBases(jwt: string): Promise<{ status: number; data: any }> {
    return super.get('/bases', jwt)
  }

  static getVendorEmail(
    filters: IFilters,
    rut: string,
    jwt: string
  ): Promise<{ status: number; data: any }> {
    return super.get(
      `/liquidaciones/providers/email?${createQueryString(filters)}&rut=${rut}`,
      jwt
    )
  }

  static changeProviderEmail(idLiquidacion: string, data: any, jwt:string): Promise<any> {
    return super.patch(`/liquidaciones/${idLiquidacion}/providers/email`, jwt, data)
  }

  static resendSettlement(
    filters: IFilters,
    rut: string,
    base: string,
		jwt: string
  ): Promise<{ status: number; data: any }> {
    return super.postMethod(
      `/liquidaciones/providers/email?${createQueryString(
        filters
      )}&base=${base}&rut=${rut}`,
			jwt
    )
  }

  static getProviderSummaryItems(
    filters: IFilters,
    rut: string,
    jwt: string
  ): Promise<{ status: number; data: any }> {
    return super.get(
      `/liquidaciones/summary?${createQueryString(filters)}&rut=${rut}`,
      jwt
    )
  }
}

export default FilterProvidersService
