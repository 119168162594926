import { IAction, IItemsState } from './items-interface'
import {
  ADD_NEW_ITEM,
  GET_LIST_ITEMS,
  SET_ACTIVE_TAB,
  SET_DISABLE_ITEM,
  SET_ENABLE_ITEM,
  SET_FETCHING,
  SHOW_CONFIRM_CREATE,
  SHOW_CONFIRM_DISABLE,
  SHOW_CONFIRM_ENABLE
} from './items-actions'

export const initialState = {
  fetching: false,
  listItems: [],
  showModalDeactivate: false,
  disableItemSelected: {
    id: '',
    name: ''
  },
  showModalActivate: false,
  enableItemSelected: {
    id: '',
    name: ''
  },
  newItem: {
    descripcion: '',
    tipoValorCosto: ''
  },
  showModalCreate: false,
  activeTab: {
    enabledItems: false,
    disableItems: false
  }
}

const ItemsReducer = (state = initialState, action: IAction): IItemsState => {
  switch (action.type) {
    case SET_FETCHING:
      return { ...state, fetching: action.payload }
    case GET_LIST_ITEMS:
      return { ...state, listItems: action.payload }
    case SHOW_CONFIRM_DISABLE:
      return { ...state, showModalDeactivate: action.payload }
    case SET_DISABLE_ITEM:
      return { ...state, disableItemSelected: action.payload }
    case SHOW_CONFIRM_ENABLE:
      return { ...state, showModalActivate: action.payload }
    case SET_ENABLE_ITEM:
      return { ...state, enableItemSelected: action.payload }
    case ADD_NEW_ITEM:
      return { ...state, newItem: action.payload }
    case SHOW_CONFIRM_CREATE:
      return { ...state, showModalCreate: action.payload }
    case SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload }
    default:
      return state
  }
}

export default ItemsReducer
