import React, { FC, useEffect, useState } from 'react'
import WideLoader from '../wide-loader' // or any spinner component

export interface IProps {
  delay?: number
}

const LazyLoader: FC<IProps> = ({ delay = 250 }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true)
    }, delay)
    return () => {
      clearTimeout(timeout)
    }
  }, [delay])

  return show ? <WideLoader /> : null
}

export { LazyLoader as default }
