import {
  FILL_BASES,
  FILL_BASES_OPTIONS,
  FILL_FILTERS,
  FILL_PREVIEW_LIST,
  GET_SUMMARY_ITEMS,
  SET_FETCHING,
  SET_NAME_BASES_LIST,
  SET_ZONE_SEL
} from './preview-actions'
import { IAction, IPreviewState, IZone } from './preview-interfaces'

export const initialState: IPreviewState = {
  fetching: false,
  zoneList: [],
  basesList: [],
  filters: {
    mes: 0,
    anio: 0,
    base: ''
  },
  basesListOptions: [{ text: 'Todas las bases', value: '' }],
  nameBasesList: [],
  summaryItems: [],
  zoneSelected: ''
}

const PreviewReducer = (
  state = initialState,
  action: IAction
): IPreviewState => {
  switch (action.type) {
    case FILL_PREVIEW_LIST:
      let payload = action.payload
      if (state.zoneSelected.length > 0) {
        payload = payload.filter(
          (item: IZone) => item.zona === state.zoneSelected
        )
      }
      return { ...state, zoneList: payload }
    case FILL_BASES:
      return { ...state, basesList: action.payload }
    case FILL_BASES_OPTIONS:
      return { ...state, basesListOptions: action.payload }
    case FILL_FILTERS:
      return { ...state, filters: action.payload }
    case SET_ZONE_SEL:
      return { ...state, zoneSelected: action.payload }
    case SET_NAME_BASES_LIST:
      return { ...state, nameBasesList: action.payload }
    case GET_SUMMARY_ITEMS:
      return { ...state, summaryItems: action.payload }
    case SET_FETCHING:
      return { ...state, fetching: action.payload }
    default:
      return state
  }
}

export default PreviewReducer
