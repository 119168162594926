import BaseService from '@core/request-service'
import { IFilters } from './finish-interfaces'
import { createQueryString } from '@utilities/index'

class FinishService extends BaseService {
  static getDetails(
    filters: IFilters,
    jwt: string
  ): Promise<{ status: number; data: any }> {
    return super.get(`/liquidaciones/close?${createQueryString(filters)}`, jwt)
  }

  static async sendPeriodClose(filters: IFilters, jwt:string): Promise<Response> {
    const endpoint = `${
      process.env.REACT_APP_API
    }/liquidaciones/close?${createQueryString(filters)}`
		let headers = new Headers()
    headers.append('Authorization', `Bearer ${jwt}`)
		headers.append('apikey', `${process.env.REACT_APP_KONG_API_KEY}`)
    const response = await fetch(endpoint, {
      method: 'PATCH',
			headers
    })
    return response
  }

  static getBases(jwt: string): Promise<{ status: number; data: any }> {
    return super.get('/bases', jwt)
  }

  static getFilters(jwt: string): Promise<any> {
    return super.get('/periodos/in-progress', jwt)
  }

  static async getClosingStatus(
    filters: IFilters,
    jwt: string
  ): Promise<{ status: number; data: any }> {
    return super.get(
      `/liquidaciones/close/status?${createQueryString(filters)}`,
      jwt
    )
  }

  static async downloadHesTemplate(filters: IFilters, jwt:string): Promise<Response> {
    const endpoint = `${
      process.env.REACT_APP_API
    }/liquidaciones/template/download?${createQueryString(
      filters
    )}&estados=Pendiente,No Enviada`
		let headers = new Headers()
    headers.append('Authorization', `Bearer ${jwt}`)
		headers.append('apikey', `${process.env.REACT_APP_KONG_API_KEY}`)
    const response = await fetch(endpoint, {
      method: 'GET',
			headers
    })
    return response
  }

  static getTotalWarning(
    filters: IFilters,
    jwt: string
  ): Promise<{ status: number; data: any }> {
    return super.get(
      `/liquidaciones/warning?${createQueryString(filters)}`,
      jwt
    )
  }
}

export default FinishService
