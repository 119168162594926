import {
  IAction,
  IBasesList,
  IBasesListOptions,
  IFilters,
  ISummaryData
} from './period-history-interfaces'

const singleKey = '@HISTORY'
const FILL_LIST = `${singleKey}/FILL_LIST`
const FILL_BASES = `${singleKey}/FILL_BASES`
const FILL_BASES_OPT = `${singleKey}/FILL_BASES_OPT`
const FILL_SUMMARY = `${singleKey}/FILL_SUMMARY`
const FILL_FILTERS = `${singleKey}/FILL_FILTERS`
const SET_ZONE_SEL = `${singleKey}/SET_ZONE_SEL`
const SET_NAME_BASES_LIST = `${singleKey}/SET_NAME_BASES_LIST`
const SET_FETCHING = `${singleKey}/SET_FETCHING`
const GET_PERIOD_LIST = `${singleKey}/GET_PERIOD_LIST`
const GET_SUMMARY_ITEMS = `${singleKey}/GET_SUMMARY_ITEMS`
const FILTERS_EXISTS = `${singleKey}/FILTERS_EXISTS`

const fillPeriodHistoryList = (data: any): IAction => {
  return { type: FILL_LIST, payload: data }
}

const fillBases = (data: Array<IBasesList>): IAction => {
  return { type: FILL_BASES, payload: data }
}

const fillBasesOptions = (data: Array<IBasesListOptions>): IAction => {
  return { type: FILL_BASES_OPT, payload: data }
}

const fillFilters = (data: IFilters): IAction => {
  return { type: FILL_FILTERS, payload: data }
}

const fillSummary = (data: ISummaryData): IAction => {
  return { type: FILL_SUMMARY, payload: data }
}

const setZoneSelected = (data: string): IAction => {
  return { type: SET_ZONE_SEL, payload: data }
}

const setNameBasesList = (data: string[]): IAction => {
  return { type: SET_NAME_BASES_LIST, payload: data }
}

const setFetching = (data: boolean): IAction => {
  return { type: SET_FETCHING, payload: data }
}

const getPeriodList = (data: any[]): IAction => {
  return { type: GET_PERIOD_LIST, payload: data }
}

const getSummaryItems = (data: any): IAction => {
  return { type: GET_SUMMARY_ITEMS, payload: data }
}

const setHistoryFilters = (data: boolean): IAction => {
  return { type: FILTERS_EXISTS, payload: data }
}

export {
  FILL_LIST,
  FILL_BASES,
  FILL_BASES_OPT,
  FILL_FILTERS,
  FILL_SUMMARY,
  SET_ZONE_SEL,
  SET_NAME_BASES_LIST,
  SET_FETCHING,
  GET_PERIOD_LIST,
  GET_SUMMARY_ITEMS,
  FILTERS_EXISTS,
  fillPeriodHistoryList,
  fillBases,
  fillBasesOptions,
  fillFilters,
  fillSummary,
  setZoneSelected,
  setFetching,
  getPeriodList,
  setNameBasesList,
  getSummaryItems,
  setHistoryFilters
}
