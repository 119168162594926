import {
  IAction,
  IFilterProvidersState,
  IZone
} from './filter-provider-interfaces'
import {
  GET_PERIOD_LIST,
  FILL_FILTERS,
  OPEN_SEND_EMAIL,
  SENT_SUCCESSFULLY,
  OPEN_EDIT_EMAIL,
  EDITED_SUCCESSFULLY,
  SET_FETCHING,
  GET_PROVIDER_DETAILS,
  SET_SELECTED_PROVIDER,
  SET_PROVIDER_FOUND,
  SET_ZONE_SEL,
  FILL_BASES_OPT,
  FILL_BASES,
  SET_BASE_SEND_EMAIL,
  SET_BASE_EDIT_EMAIL,
  GET_SUMMARY_ITEMS
} from './filter-providers-actions'

export const initialState: IFilterProvidersState = {
  fetching: false,
  zoneList: [],
  basesList: [],
  filters: {
    mes: '',
    anio: '',
    base: ''
  },
  basesListOptions: [{ text: 'Todas las bases', value: '' }],
  zoneSelected: '',
  periodList: [],
  baseSelectedEditEmail: '',
  baseSelectedSendEmail: '',
  summaryItems: [],
  providerFound: {
    email: '',
    nombre: '',
    rut: '',
    filters: {
      mes: '',
      anio: '',
      base: ''
    }
  },
  selectedProvider: {
    id: '',
    rut: '',
    nombre: '',
    email: 'recuperando email...'
  },
  modalToSendEmail: false,
  modalEmailSentSuccess: false,
  modalToEditEmail: false,
  modalSuccessEditedEmail: false
}

const FilterProvidersReducer = (
  state = initialState,
  action: IAction
): IFilterProvidersState => {
  switch (action.type) {
    case GET_PROVIDER_DETAILS:
      let payload = action.payload
      if (state.zoneSelected.length > 0) {
        payload = payload.filter(
          (item: IZone) => item.zona === state.zoneSelected
        )
      }
      return { ...state, zoneList: payload }
    case GET_PERIOD_LIST:
      return { ...state, periodList: action.payload }
    case FILL_FILTERS:
      return { ...state, filters: action.payload }
    case FILL_BASES:
      return { ...state, basesList: action.payload }
    case SET_ZONE_SEL:
      return { ...state, zoneSelected: action.payload }
    case FILL_BASES_OPT:
      return { ...state, basesListOptions: action.payload }
    case SET_BASE_SEND_EMAIL:
      return { ...state, baseSelectedSendEmail: action.payload }
    case SET_BASE_EDIT_EMAIL:
      return { ...state, baseSelectedEditEmail: action.payload }
    case SET_PROVIDER_FOUND:
      return { ...state, providerFound: action.payload }
    case SET_SELECTED_PROVIDER:
      return { ...state, selectedProvider: action.payload }
    case SET_FETCHING:
      return { ...state, fetching: action.payload }
    case OPEN_SEND_EMAIL:
      return { ...state, modalToSendEmail: action.payload }
    case SENT_SUCCESSFULLY:
      return { ...state, modalEmailSentSuccess: action.payload }
    case OPEN_EDIT_EMAIL:
      return { ...state, modalToEditEmail: action.payload }
    case EDITED_SUCCESSFULLY:
      return { ...state, modalSuccessEditedEmail: action.payload }
    case GET_SUMMARY_ITEMS:
      return { ...state, summaryItems: action.payload }
    default:
      return state
  }
}

export default FilterProvidersReducer
