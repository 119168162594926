import BaseService from '@core/request-service'
import { IFilters } from './items-extract-interfaces'
import { createQueryString } from '@utilities/index'

class ItemsExtractService extends BaseService {
  static getDetails(filters: IFilters, jwt:string): Promise<{ status: number; data: any }> {
    return super.get(
      `/resumen-items/details?${createQueryString(
        filters
      )}`,
			jwt
    )
  }

  static sendFileSummaryItems(
    filters: IFilters,
    params: any,
		jwt: string
  ): Promise<{ status: number; data: any }> {
    return super.post(
      `/resumen-items/upload?${createQueryString(
        filters
      )}`,
      params,
      jwt
    )
  }

  static sendFiledd(
    filters: IFilters,
    params: any,
		jwt:string
  ): Promise<{ status: number; data: any }> {
    return super.post(
      `/items/dd/upload?${createQueryString(filters)}`,
      params,
      jwt
    )
  }

  static async downloadTemplateItems(jwt:string): Promise<Response> {
    const endpoint = `${process.env.REACT_APP_API}/resumen-items/template/download`
		let headers = new Headers()
		headers.append('Authorization', `Bearer ${jwt}`)
		headers.append('apikey', `${process.env.REACT_APP_KONG_API_KEY}`)
    const response = await fetch(endpoint, {
      method: 'GET',
			headers
    })
    return response
  }

  static async downloadTemplatedd(jwt:string): Promise<Response> {
    const endpoint = `${process.env.REACT_APP_API}/items/dd/template/download`
		let headers = new Headers()
		headers.append('Authorization', `Bearer ${jwt}`)
		headers.append('apikey', `${process.env.REACT_APP_KONG_API_KEY}`)
    const response = await fetch(endpoint, {
      method: 'GET',
			headers
    })
    return response
  }

  static getBases(jwt:string): Promise<{ status: number; data: any }> {
    return super.get('/bases', jwt)
  }

  static getFilters(jwt: string): Promise<any> {
    return super.get('/periodos/in-progress', jwt)
  }

  static getTotalWarning(
    filters: IFilters,
		jwt: string
  ): Promise<{ status: number; data: any }> {
    return super.get(
      `/liquidaciones/warning?${createQueryString(
        filters
      )}`,
			jwt
    )
  }
}

export default ItemsExtractService
