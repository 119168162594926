import BaseService from '@core/request-service'
import { IFilters } from './period-history-interfaces'
import { createQueryString } from '@utilities/index'

class PeriodHistoryService extends BaseService {
  static getDetails(filters: IFilters, jwt:string): Promise<{ status: number; data: any }> {
    return super.get(
      `/liquidaciones/close?${createQueryString(filters)}`,
			jwt
    )
  }

  static getBases(jwt:string): Promise<{ status: number; data: any }> {
    return super.get('/bases', jwt)
  }

  static getFilters(jwt:string): Promise<any> {
    return super.get('/periodos/in-progress', jwt)
  }

  static getPeriodSummary(
    filters: IFilters,
		jwt: string,
    bases?: any
  ): Promise<{ status: number; data: any }> {
    return super.get(
      `/resumen-costos/summary?${createQueryString(
        filters
      )}&bases=${bases}`,
			jwt
    )
  }

  static getSummaryItems(
    filters: IFilters,
		jwt: string
  ): Promise<{ status: number; data: any }> {
    return super.get(
      `/liquidaciones/summary?${createQueryString(
        filters
      )}`,
			jwt
    )
  }

  static async downloadHesInfo(filters: IFilters, jwt:string): Promise<Response> {
    const endpoint = `${
      process.env.REACT_APP_API
    }/liquidaciones/download?${createQueryString(filters)}`
		let headers = new Headers()
		headers.append('Authorization', `Bearer ${jwt}`)
		headers.append('apikey', `${process.env.REACT_APP_KONG_API_KEY}`)
    const response = await fetch(endpoint, {
      method: 'GET',
			headers
    })
    return response
  }

  static getPeriodHistory(jwt:string): Promise<any> {
    return super.get(`/periodos?state=Closed&limit=${process.env.REACT_APP_PERIOD_FILTER}`,jwt)
  }
}

export default PeriodHistoryService
