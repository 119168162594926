import {
  FILL_DISCOUNT_LIST,
  FILL_FILTERS,
  SET_FETCHING,
  SET_SUCCESS_MODAL
} from './discount-prioritization-actions'
import { IAction, IDiscountState } from './discount-prioritization-interfaces'

export const initialState = {
  fetching: false,
  filters: {
    mes: 0,
    anio: 0,
    base: ''
  },
  discountList: [],
  showSuccessfulModal: false
}

const DiscountPrioritizationReducer = (
  state = initialState,
  action: IAction
): IDiscountState => {
  switch (action.type) {
    case FILL_DISCOUNT_LIST:
      return { ...state, discountList: action.payload }
    case FILL_FILTERS:
      return { ...state, filters: action.payload }
    case SET_FETCHING:
      return { ...state, fetching: action.payload }
    case SET_SUCCESS_MODAL:
      return { ...state, showSuccessfulModal: action.payload }
    default:
      return state
  }
}

export default DiscountPrioritizationReducer
