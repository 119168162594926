const UPDATE_SELECTED = '@NAVIGATION/UPDATE_SELECTED'

const updateSelected = (sel: string) => {
  return {
    type: UPDATE_SELECTED,
    payload: sel
  }
}

export { UPDATE_SELECTED, updateSelected }
