import { SHOW_WARNING, TOTAL_PROVIDERS } from './providers-alert-actions'
import { IAction, IProvidersAlertState } from './providers-alert-interfaces'

const initialState = {
  totalProviders: 0,
  showWarning: true
}

const ProvidersAlertReducer = (
  state = initialState,
  action: IAction
): IProvidersAlertState => {
  switch (action.type) {
    case TOTAL_PROVIDERS:
      return { ...state, totalProviders: action.payload }
    case SHOW_WARNING:
      return { ...state, showWarning: action.payload }
    default:
      return state
  }
}

export default ProvidersAlertReducer
