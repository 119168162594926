import BaseService from '@core/request-service'
import { IFilters } from '@features/cost-extract/cost-extract-interfaces'
import createQueryString from '@utilities/query-string'

export default class CostExtractService extends BaseService {
  static getDetails(
    filters: IFilters,
    jwt: string
  ): Promise<{ status: number; data: any }> {
    return super.get(
      `/resumen-costos/details?${createQueryString(filters)}`,
      jwt
    )
  }

  static getPeriodSummary(
    filters: IFilters,
    jwt: string,
    bases?: any
  ): Promise<{ status: number; data: any }> {
    return super.get(
      `/resumen-costos/summary?${createQueryString(filters)}&bases=${bases}`,
      jwt
    )
  }

  static getSummaryItems(
    filters: IFilters,
    jwt: string
  ): Promise<{ status: number; data: any }> {
    return super.get(
      `/liquidaciones/summary?${createQueryString(filters)}`,
      jwt
    )
  }

  static getBases(jwt: string): Promise<{ status: number; data: any }> {
    return super.get('/bases', jwt)
  }

  static getFilters(jwt: string): Promise<any> {
    return super.get('/periodos/in-progress', jwt)
  }

  static getTotalWarning(
    filters: IFilters,
    jwt: string
  ): Promise<{ status: number; data: any }> {
    return super.get(
      `/liquidaciones/warning?${createQueryString(filters)}`,
      jwt
    )
  }
}
