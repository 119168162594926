import { UPDATE_SELECTED } from './sidenav-actions'

export interface ISidenavState {
  selected: string
}

type Actions = { type: typeof UPDATE_SELECTED; payload: string }

export const SidenavReducer = (state: ISidenavState, action: Actions) => {
  state = state ? state : { selected: '/' }
  switch (action.type) {
    case UPDATE_SELECTED:
      return { selected: action.payload }
    default:
      return state
  }
}
