import CostExtractService from './cost-extract-service'
import {
  fillList,
  fillSummary,
  fillBases,
  setFetching,
  fillFilters,
  getSummaryItems,
  setZoneSelected,
  setNameBasesList,
  fillBasesOptions
} from './cost-extract-actions'
import mapOrder from '@utilities/map-order'
import { setTotalWarning } from '@commons/providers-alert/providers-alert-actions'
import { initialState } from './cost-extract-reducer'
import { emptyList, zoneOrder } from 'src/constants'
import { ICollectionItems } from './cost-extract-interfaces'
import { AppThunk } from '@commons/thunk-types'

const fillAllData = (): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    CostExtractService.getFilters(getState().user.jwtoken)
      .then(response => {
        if (response.status === 200) {
          const filters = response.data
          dispatch(fillFilters({ ...filters, base: '' }))
          dispatch(fillDataList({ summaryData: true }))
          dispatch(getTotalWarning())
        } else {
          dispatch(setFetching(false))
        }
      })
      .catch(error => {
        dispatch(setFetching(false))
        console.error('Error trying to get data', error)
      })
  }
}

const fillDataList = ({ summaryData }: { summaryData: boolean }): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    const { costExtract } = getState()
    CostExtractService.getDetails(
      { ...costExtract.filters },
      getState().user.jwtoken
    )
      .then(response => {
        if (response.status === 200) {
          dispatch(fillList(mapOrder(response.data, zoneOrder, 'zona')))
          if (summaryData) {
            dispatch(fillSummaryData())
          }
        } else {
          const { base } = costExtract.filters
          dispatch(fillList(mapOrder(base ? emptyList : [], zoneOrder, 'zona')))
          dispatch(setFetching(false))
        }
      })
      .catch(error => {
        console.error('Error trying to get data', error)
        dispatch(setFetching(false))
      })
      .finally(() => {
        if (!summaryData) dispatch(setFetching(false))
      })
  }
}

const fillSummaryData = (): AppThunk => {
  return (dispatch, getState) => {
    const { costExtract } = getState()
    CostExtractService.getPeriodSummary(
      { ...costExtract.filters },
      getState().user.jwtoken,
      costExtract.nameBasesList
    )
      .then(response => {
        if (response.status === 200) {
          dispatch(fillSummary(response.data))
          dispatch(fillSummaryItems(costExtract.zoneSelected))
        } else {
          dispatch(fillSummary(initialState.summaryData))
          dispatch(fillSummaryItems())
        }
      })
      .catch(error => console.error('Error trying to get data', error))
  }
}

const fillSummaryItems = (zone?: string): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    const { costExtract } = getState()
    CostExtractService.getSummaryItems(
      { ...costExtract.filters },
      getState().user.jwtoken
    )
      .then(response => {
        if (response.status === 200) {
          const summaryItems = response.data
          if (!zone) zone = 'Todas'
          const items = summaryItems.filter(
            (item: ICollectionItems) => item.zona === zone
          )
          dispatch(getSummaryItems(items[0].items))
        }
      })
      .catch(error => console.error('Error trying to get data', error))
      .finally(() => dispatch(setFetching(false)))
  }
}

const fillBasesList = (): AppThunk => {
  return (dispatch, getState) => {
    CostExtractService.getBases(getState().user.jwtoken)
      .then(response => {
        if (response.status === 200) return dispatch(fillBases(response.data))
      })
      .catch(error => console.error('Error trying to get data', error))
  }
}

const getTotalWarning = (): AppThunk => {
  return (dispatch, getState) => {
    const { costExtract } = getState()
    CostExtractService.getTotalWarning(
      { ...costExtract.filters },
      getState().user.jwtoken
    )
      .then(response => {
        if (response.status === 200)
          return dispatch(setTotalWarning(response.data.totalWarning))
      })
      .catch(error => console.error('Error trying to get data', error))
  }
}

const removeZoneFilters = (): AppThunk => {
  return (dispatch, getState) => {
    const { costExtract } = getState()
    costExtract.filters.base = ''
    dispatch(fillFilters(costExtract.filters))
    dispatch(setZoneSelected(initialState.zoneSelected))
    dispatch(setNameBasesList(initialState.nameBasesList))
    dispatch(fillBasesOptions(initialState.basesListOptions))
  }
}

export {
  fillDataList,
  fillSummaryData,
  fillBasesList,
  fillAllData,
  fillSummaryItems,
  getTotalWarning,
  removeZoneFilters
}
