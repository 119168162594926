import React, { FC, useCallback, useEffect, lazy, Suspense } from 'react'
import { Switch, BrowserRouter as Router } from 'react-router-dom'
import PrivateRoute from './routes/private-routes'
import { useDispatch, useSelector } from 'react-redux'
import { IInitialRootState } from './store'
import { getDataLocalStorage } from './utilities'
import Processing from '@commons/processing'
import PublicRoute from './routes/public-routes'
import { UserActions, UserThunks } from '@commons/user'
import { routes } from './constants'
import LazyLoader from '@commons/lazyLoader'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { datadogRum } from '@datadog/browser-rum'

const LoginPage = lazy(() => import('@features/login/login-page'))
const PreviewPage = lazy(() => import('@features/preview/preview-page'))
const FinishPage = lazy(() => import('@features/finish/finish-page'))
const TransfersIndex = lazy(() => import('@features/transfers/home/home-page'))
const CostExtractPage = lazy(
  () => import('@features/cost-extract/cost-extract-page')
)
const ItemsExtractPage = lazy(
  () => import('@features/items-extract/items-extract-page')
)
const PeriodHistory = lazy(
  () => import('@features/period-history/period-history-page')
)
const MaintainerIndex = lazy(
  () => import('@features/maintainers/home/home-page')
)
const FilterProviders = lazy(
  () => import('@features/filter-providers/filter-providers-page')
)

const App: FC = () => {
  ReactGA.initialize(`${process.env.REACT_GOOGLE_ANALITYCS}`)
  ReactGA.pageview(window.location.pathname + window.location.search)
  const { userLogged, validating } = useSelector(
    (state: IInitialRootState) => state.user
  )
  const dispatch = useDispatch()
  const token = getDataLocalStorage('jwt')

  const validateToken = useCallback(() => {
    dispatch(UserThunks.validateUserToken(token))
  }, [dispatch, token])

  useEffect(() => {
    if (token && !userLogged) {
      validateToken()
    } else {
      dispatch(UserActions.updateWaitUserValidation(false))
    }
  }, [dispatch, validateToken, userLogged, token])

  useEffect(() => {
    datadogRum.init({
      applicationId: `${process.env.REACT_APP_APPLICATION_ID}`,
      clientToken: `${process.env.REACT_APP_CLIENT_TOKEN}`,
      site: 'datadoghq.com',
      service: 'prueba-1',
      sampleRate: 100,
      trackInteractions: true
    })
  }, [])

  useEffect(() => {
    if (userLogged) {
      const tagManagerArgs = {
        gtmId: `${process.env.REACT_APP_GTM_ID}`,
        dataLayer: {
          userId: userLogged.sub,
          userProject: 'liquidaciones'
        }
      }
      TagManager.initialize(tagManagerArgs)
    }
  }, [userLogged])

  return (
    <>
      {validating && token ? (
        <Processing show={validating} />
      ) : (
        <>
          <Router>
            <Suspense fallback={<LazyLoader delay={50} />}>
              <Switch>
                <PublicRoute
                  path="/"
                  isSignedIn={userLogged}
                  exact
                  component={LoginPage}
                />
                <PublicRoute
                  path={routes.login}
                  isSignedIn={userLogged}
                  exact
                  component={LoginPage}
                />
                <PrivateRoute
                  path={routes.filterProviders}
                  isSignedIn={userLogged}
                  exact
                  component={FilterProviders}
                />
                <PrivateRoute
                  path={routes.finish}
                  isSignedIn={userLogged}
                  exact
                  component={FinishPage}
                />
                <PrivateRoute
                  path={routes.preview}
                  isSignedIn={userLogged}
                  component={PreviewPage}
                />
                <PrivateRoute
                  path={routes.itemExtract}
                  isSignedIn={userLogged}
                  component={ItemsExtractPage}
                />
                <PrivateRoute
                  path={routes.costExtract}
                  isSignedIn={userLogged}
                  component={CostExtractPage}
                />
                <PrivateRoute
                  path={routes.periodHistory}
                  isSignedIn={userLogged}
                  component={PeriodHistory}
                />
                <PrivateRoute
                  path={routes.mantenedores}
                  isSignedIn={userLogged}
                  component={MaintainerIndex}
                />
                <PrivateRoute
                  path={routes.transfers}
                  isSignedIn={userLogged}
                  component={TransfersIndex}
                />
              </Switch>
            </Suspense>
          </Router>
        </>
      )}
    </>
  )
}

export default App
