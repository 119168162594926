import { IAction } from '@commons/commons-interfaces'

const singleKey = '@AUTH'
const UPDATE_JWTOKEN = `${singleKey}/UPDATE_JWTOKEN`
const UPDATE_USER_LOGGED = `${singleKey}/UPDATE_USER_DATA`
const WAIT_USER_VALIDATION = `${singleKey}/WAIT_USER_VALIDATION`
const SET_USERNAME = `${singleKey}/SET_USERNAME`

const updateJWToken = (jwt: string): IAction => {
  return { type: UPDATE_JWTOKEN, payload: jwt }
}

const updateUserLogged = (data: unknown): IAction => {
  return { type: UPDATE_USER_LOGGED, payload: data }
}

const updateWaitUserValidation = (value: boolean): IAction => {
  return { type: WAIT_USER_VALIDATION, payload: value }
}

const updateUsername = (value: string): IAction => {
  return { type: SET_USERNAME, payload: value }
}

export {
  UPDATE_JWTOKEN,
  UPDATE_USER_LOGGED,
  WAIT_USER_VALIDATION,
  SET_USERNAME,
  updateJWToken,
  updateUserLogged,
  updateWaitUserValidation,
  updateUsername
}
