import {
  FILL_FILTERS,
  SET_FETCHING,
  SET_HISTORY_DATA,
  SET_PERIOD_LIST,
  SET_PAGE,
  SET_IN_PROGRESS,
  SHOW_CONFIRM_REVERSE,
  SET_REVERSE_ID,
  SHOW_SUCCESS_MODAL
} from './transfer-history-actions'
import { IAction, ITransferHistoryState } from './transfer-history-interfaces'

export const initialState: ITransferHistoryState = {
  fetching: true,
  filters: {
    mes: 0,
    anio: 0,
    base: ''
  },
  historyList: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    totalPages: 0,
    page: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null
  },
  periodList: [],
  page: 1,
  inProgress: {
    mes: 0,
    anio: 0,
    base: ''
  },
  showConfirm: false,
  showSuccess: false,
  reverseTransferId: ''
}

const TransferHistoryReducer = (
  state = initialState,
  action: IAction
): ITransferHistoryState => {
  switch (action.type) {
    case FILL_FILTERS:
      return { ...state, filters: action.payload }
    case SET_FETCHING:
      return { ...state, fetching: action.payload }
    case SET_HISTORY_DATA:
      return { ...state, historyList: action.payload }
    case SET_PERIOD_LIST:
      return { ...state, periodList: action.payload }
    case SET_PAGE:
      return { ...state, page: action.payload }
    case SET_IN_PROGRESS:
      return { ...state, inProgress: action.payload }
    case SHOW_CONFIRM_REVERSE:
      return { ...state, showConfirm: action.payload }
    case SET_REVERSE_ID:
      return { ...state, reverseTransferId: action.payload }
    case SHOW_SUCCESS_MODAL:
      return { ...state, showSuccess: action.payload }
    default:
      return state
  }
}

export default TransferHistoryReducer
