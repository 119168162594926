import { IResponseService } from '@commons/commons-interfaces'

/**
 *  @description We retrieve token from local storage
 */


/**
 * @description Base class for Api Request
 * extends your services using this class if you want
 */
export default class RequestService {
  /**
   * @description Fetch Data using GET method
   * @param {string} endpoint requested url
   * @param {object} jwt JSON Web Token
   */

  static async get(
    endpoint: string,
		jwt: string
    // headers?: Headers | string[][] | Record<string, string> | undefined
  ) {
    const headers = new Headers()
    headers.append('Authorization', `Bearer ${jwt}`)
		headers.append('apikey', `${process.env.REACT_APP_KONG_API_KEY}`)
    const response = await fetch(`${process.env.REACT_APP_API}${endpoint}`, {
      method: 'GET',
      headers
    })
    const data = response.status === 200 ? await response.json() : []
    return {
      status: response.status,
      data
    }
  }

  /**
   * @description Send data using PUT method
   * @param {string} endpoint requested url
   * @param {object} params object used like body data
   * @param {object} headers Headers object used on the request
   */

  static async post(
    endpoint: string,
    params: any,
		jwt:string
    // headers: Headers | string[][] | Record<string, string> | undefined
  ) {
		const headers = new Headers()
    headers.append('Authorization', `Bearer ${jwt}`)
		headers.append('apikey', `${process.env.REACT_APP_KONG_API_KEY}`)
    const bodyData = new FormData()
    Object.keys(params).forEach(value => bodyData.append(value, params[value]))

    const response = await fetch(`${process.env.REACT_APP_API}${endpoint}`, {
      method: 'POST',
      body: bodyData,
      headers
    })
    const data = {}
    return {
      status: response.status,
      data
    }
  }

  /**
   * @description Send data using PUT method
   * @param {string} endpoint requested url
   * @param {object} bodyData object used like body data
   */

  static async put(endpoint: string, bodyData: any, jwt:string) {
    let headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Authorization', `Bearer ${jwt}`)
		headers.append('apikey', `${process.env.REACT_APP_KONG_API_KEY}`)
    const response = await fetch(`${process.env.REACT_APP_API}${endpoint}`, {
      method: 'PUT',
      body: JSON.stringify(bodyData),
      headers
    })
    const data = response.ok ? await response.json() : null
    return {
      httpCode: response.status,
      data
    }
  }

  /**
   * @description Send data using PATCH method
   * @param {string} endpoint requested url
   * @param {object} bodyData object used like body data
   */

  static async patch(endpoint: string, jwt:string, bodyData?: any) {
    let headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Authorization', `Bearer ${jwt}`)
		headers.append('apikey', `${process.env.REACT_APP_KONG_API_KEY}`)
    const response = await fetch(`${process.env.REACT_APP_API}${endpoint}`, {
      method: 'PATCH',
      body: JSON.stringify(bodyData),
      headers
    })
    const data = response.ok ? await response.json() : null
    return {
      httpCode: response.status,
      data
    }
  }

  /**
   * @description Send data using POST method and x-www-form-urlencoded
   * @param {string} endpoint requested url
   * @param {object} params object used like body data
   */

  static async postFormUrlEncoded(
    endpoint: string,
    params: any
  ): Promise<IResponseService> {
    const formBody = Object.keys(params)
      .map(
        key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
      )
      .join('&')
    let headers = new Headers()
    headers.append('Content-Type', 'application/x-www-form-urlencoded')
    const response = await fetch(endpoint, {
      method: 'POST',
      headers,
      body: formBody
    })
    const data = response.ok ? await response.json() : null
    return {
      httpCode: response.status,
      data
    }
  }

  /**
   * @description Send data using POST method to verify authenticated user's token
   * @param {string} endpoint requested url
   * @param {string} token authentication token of the logged-in user saved in localstorage
   */

  static async postValidateUserToken(
    endpoint: string,
    token: string
  ): Promise<any> {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: ''
    })
    const data = response.ok ? await response.json() : null
    return {
      httpCode: response.status,
      data
    }
  }

  /**
   * @description Send data using POST method with body data
   * @param {string} endpoint requested url
   * @param {string} headers Headers object used on the request
   * @param {string} body body data
   */

  static async postMethod(endpoint: string, jwt:string, body?: any | undefined) {
    let headers = new Headers()
    headers.append('Content-Type', 'application/json')
		headers.append('Authorization', `Bearer ${jwt}`)
		headers.append('apikey', `${process.env.REACT_APP_KONG_API_KEY}`)
    const response = await fetch(`${process.env.REACT_APP_API}${endpoint}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers
    })
    const data = {}
    return {
      status: response.status,
      data
    }
  }

  /**
   * @description delete data using DELETE method
   * @param {string} endpoint requested url
   * @param {object} bodyData object used like body data
   */

  static async delete(endpoint: string, jwt:string, bodyData?: any) {
    let headers = new Headers()
    headers.append('Content-Type', 'application/json')
		headers.append('Authorization', `Bearer ${jwt}`)
		headers.append('apikey', `${process.env.REACT_APP_KONG_API_KEY}`)
    const response = await fetch(`${process.env.REACT_APP_API}${endpoint}`, {
      method: 'DELETE',
      body: JSON.stringify(bodyData),
      headers
    })
    return {
      httpCode: response.status
    }
  }
}
