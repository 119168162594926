import {
  IAction,
  IBasesList,
  IBasesListOptions,
  IFilters,
  IProviderFound,
  ISelectedProvider
} from './filter-provider-interfaces'

const singleKey = '@PROVIDERS'
const FILL_FILTERS = `${singleKey}/FILL_FILTERS`
const FILL_BASES = `${singleKey}/FILL_BASES`
const SET_ZONE_SEL = `${singleKey}/SET_ZONE_SEL`
const FILL_BASES_OPT = `${singleKey}/FILL_BASES_OPT`
const GET_SUMMARY_ITEMS = `${singleKey}/GET_SUMMARY_ITEMS`
const GET_PERIOD_LIST = `${singleKey}/GET_PERIOD_LIST`
const SET_FETCHING = `${singleKey}/SET_FETCHING`
const GET_PROVIDER_DETAILS = `${singleKey}/GET_PROVIDER_DETAILS`
const SET_BASE_SEND_EMAIL = `${singleKey}/SET_BASE_SEND_EMAIL`
const SET_BASE_EDIT_EMAIL = `${singleKey}/SET_BASE_EDIT_EMAIL`
const SET_SELECTED_PROVIDER = `${singleKey}/SET_SELECTED_PROVIDER`
const SET_PROVIDER_FOUND = `${singleKey}/SET_PROVIDER_FOUND`
const OPEN_SEND_EMAIL = `${singleKey}/OPEN_SEND_EMAIL`
const SENT_SUCCESSFULLY = `${singleKey}/SENT_SUCCESSFULLY`
const OPEN_EDIT_EMAIL = `${singleKey}/OPEN_EDIT_EMAIL`
const EDITED_SUCCESSFULLY = `${singleKey}/EDITED_SUCCESSFULLY`

const fillFilters = (data: IFilters): IAction => {
  return { type: FILL_FILTERS, payload: data }
}

const fillBases = (data: Array<IBasesList>): IAction => {
  return { type: FILL_BASES, payload: data }
}

const setZoneSelected = (data: string): IAction => {
  return { type: SET_ZONE_SEL, payload: data }
}

const fillBasesOptions = (data: Array<IBasesListOptions>): IAction => {
  return { type: FILL_BASES_OPT, payload: data }
}

const getSummaryItems = (data: any): IAction => {
  return { type: GET_SUMMARY_ITEMS, payload: data }
}

const getPeriodList = (data: any): IAction => {
  return { type: GET_PERIOD_LIST, payload: data }
}

const setFetching = (data: boolean): IAction => {
  return { type: SET_FETCHING, payload: data }
}

const getProviderDetails = (data: any): IAction => {
  return { type: GET_PROVIDER_DETAILS, payload: data }
}

const setBaseSendEmail = (data: string): IAction => {
  return { type: SET_BASE_SEND_EMAIL, payload: data }
}

const setBaseEditEmail = (data: string): IAction => {
  return { type: SET_BASE_EDIT_EMAIL, payload: data }
}

const setProviderFound = (data: IProviderFound): IAction => {
  return { type: SET_PROVIDER_FOUND, payload: data }
}

const openSendEmail = (data: boolean): IAction => {
  return { type: OPEN_SEND_EMAIL, payload: data }
}

const sentSuccessfully = (data: boolean): IAction => {
  return { type: SENT_SUCCESSFULLY, payload: data }
}

const setSelectedProvider = (data: ISelectedProvider): IAction => {
  return { type: SET_SELECTED_PROVIDER, payload: data }
}

const openEditEmail = (data: boolean): IAction => {
  return { type: OPEN_EDIT_EMAIL, payload: data }
}

const editedSuccessfully = (data: boolean): IAction => {
  return { type: EDITED_SUCCESSFULLY, payload: data }
}

export {
  FILL_FILTERS,
  FILL_BASES,
  SET_ZONE_SEL,
  FILL_BASES_OPT,
  GET_SUMMARY_ITEMS,
  GET_PERIOD_LIST,
  SET_FETCHING,
  GET_PROVIDER_DETAILS,
  SET_BASE_SEND_EMAIL,
  SET_BASE_EDIT_EMAIL,
  SET_SELECTED_PROVIDER,
  SET_PROVIDER_FOUND,
  OPEN_SEND_EMAIL,
  SENT_SUCCESSFULLY,
  OPEN_EDIT_EMAIL,
  EDITED_SUCCESSFULLY,
  fillFilters,
  fillBases,
  setZoneSelected,
  fillBasesOptions,
  getSummaryItems,
  getPeriodList,
  setFetching,
  getProviderDetails,
  setBaseSendEmail,
  setBaseEditEmail,
  setSelectedProvider,
  setProviderFound,
  openSendEmail,
  sentSuccessfully,
  openEditEmail,
  editedSuccessfully
}
