import { toast } from 'react-toastify'
import {
  setZoneSelected,
  editedSuccessfully,
  fillBases,
  fillBasesOptions,
  fillFilters,
  getPeriodList,
  getProviderDetails,
  getSummaryItems,
  openEditEmail,
  openSendEmail,
  sentSuccessfully,
  setBaseEditEmail,
  setBaseSendEmail,
  setFetching,
  setProviderFound,
  setSelectedProvider
} from './filter-providers-actions'
import FilterProvidersService from './filter-providers-service'
import mapOrder from '@utilities/map-order'
import { initialState } from './filter-providers-reducers'
import { AppThunk } from '@commons/thunk-types'
import { zoneOrder } from '@constants/index'
import { ICollectionItems } from './filter-provider-interfaces'

const findProviderByRut = (
  searchText: string,
  baseChange?: boolean
): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    const {
      filterProviders: { filters }
    } = getState()
    FilterProvidersService.getProviders(
      { ...filters },
      searchText.trim(),
      getState().user.jwtoken
    )
      .then(response => {
        if (response.status === 200) {
          const provider = response.data[0]
          if (!provider) {
            dispatch(getProviderDetails([]))
            dispatch(setProviderFound(initialState.providerFound))
            toast.info(`No se encontrar resultados para "${searchText}"`)
            dispatch(setFetching(false))
            return
          }
          dispatch(getSupplierDetails(provider, baseChange))
        } else {
          toast.error(
            'No se pudo realizar la búsqueda por favor intente más tarde'
          )
          dispatch(setFetching(false))
        }
      })
      .catch(error => {
        dispatch(setFetching(true))
        console.error('Error trying to get data', error)
      })
  }
}

const getSupplierDetails = (provider: any, baseChange?: boolean): AppThunk => {
  return (dispatch, getState) => {
    const { rut } = provider
    const {
      filterProviders: { filters, zoneSelected }
    } = getState()
    FilterProvidersService.getProviderDetails(
      { ...filters },
      rut,
      getState().user.jwtoken
    )
      .then(response => {
        if (response.status === 200) {
          dispatch(setProviderFound({ ...provider, filters }))
          dispatch(
            getProviderDetails(mapOrder(response.data, zoneOrder, 'zona'))
          )
          if (!baseChange)
            return dispatch(fillProviderSummaryItems(rut, zoneSelected))
          return
        }
        if (response.status === 204) {
          dispatch(getProviderDetails([]))
          return
        }
        toast.error(
          'No se pudo realizar la búsqueda por favor intente más tarde'
        )
      })
      .catch(error => console.error('Error trying to get data', error))
      .finally(() => dispatch(setFetching(false)))
  }
}

const getPeriodHistory = (): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    FilterProvidersService.getPeriodHistory(getState().user.jwtoken)
      .then(response => {
        if (response.status === 200) {
          dispatch(getPeriodList(response.data))
          const { estado, ...filters } = response.data[0]
          dispatch(fillFilters({ ...filters, base: '' }))
          return
        }
      })
      .catch(error => console.error('Error trying to get data', error))
      .finally(() => dispatch(setFetching(false)))
  }
}

const fillBasesList = (): AppThunk => {
  return (dispatch, getState) => {
    FilterProvidersService.getBases(getState().user.jwtoken)
      .then(response => {
        if (response.status === 200) {
          dispatch(fillBases(response.data))
          return
        }
      })
      .catch(error => console.error('Error trying to get data', error))
  }
}

const getVendorEmail = (base: string, rut: string): AppThunk => {
  return (dispatch, getState) => {
    const {
      filterProviders: { filters }
    } = getState()
    const currentFilters = { ...filters, base }
    FilterProvidersService.getVendorEmail(
      currentFilters,
      rut,
      getState().user.jwtoken
    )
      .then(response => {
        if (response.status === 200)
          dispatch(setSelectedProvider(response.data))
      })
      .catch(error => console.error('Error trying to get data', error))
  }
}

const changeProviderEmail = (idLiquidacion: string, data: any): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    FilterProvidersService.changeProviderEmail(
      idLiquidacion,
      data,
      getState().user.jwtoken
    )
      .then(response => {
        if (response.httpCode === 200) {
          dispatch(setBaseEditEmail(''))
          dispatch(setSelectedProvider(initialState.selectedProvider))
          dispatch(openEditEmail(false))
          dispatch(editedSuccessfully(true))
        } else {
          dispatch(setBaseEditEmail(''))
          dispatch(setSelectedProvider(initialState.selectedProvider))
          dispatch(openEditEmail(false))
          toast.error(
            'Ocurrió un error al momento de actualizar el email por favor intente más tarde.'
          )
        }
      })
      .catch(error => console.error('Error trying to get data', error))
      .finally(() => dispatch(setFetching(false)))
  }
}

const resendSettlement = (base: string, rut: string): AppThunk => {
  return (dispatch, getState: any) => {
    dispatch(setFetching(true))
    const {
      filterProviders: { filters }
    } = getState()
    const currentFilters = { ...filters }
    delete currentFilters.base
    FilterProvidersService.resendSettlement({ ...currentFilters }, rut, base, getState().user.jwtoken)
      .then(response => {
        if (response.status === 200) {
          dispatch(setBaseSendEmail(''))
          dispatch(openSendEmail(false))
          dispatch(dispatch(sentSuccessfully(true)))
        } else {
          dispatch(setBaseSendEmail(''))
          dispatch(openSendEmail(false))
          toast.error('Ocurrió un error al momento de reenviar la liquidación')
        }
      })
      .catch(error => console.error('Error trying to get data', error))
      .finally(() => dispatch(setFetching(false)))
  }
}

const fillProviderSummaryItems = (rut: string, zone?: string): AppThunk => {
  return (dispatch, getState) => {
    const {
      filterProviders: { filters }
    } = getState()
    FilterProvidersService.getProviderSummaryItems(
      { ...filters },
      rut,
      getState().user.jwtoken
    )
      .then(response => {
        if (response.status === 200) {
          const summaryItems = response.data
          if (!zone) zone = 'Todas'
          const items = summaryItems.filter(
            (item: ICollectionItems) => item.zona === zone
          )
          if (items.length === 0) {
            dispatch(getSummaryItems(initialState.summaryItems))
            return
          }
          dispatch(getSummaryItems(items[0].items))
        }
        if (response.status === 204) {
          dispatch(getSummaryItems(initialState.summaryItems))
        }
      })
      .catch(error => console.error('Error trying to get data', error))
  }
}

const removeZoneFilters = (): AppThunk => {
  return dispatch => {
    dispatch(setProviderFound(initialState.providerFound))
    dispatch(fillBasesOptions(initialState.basesListOptions))
    dispatch(getProviderDetails([]))
    dispatch(getSummaryItems([]))
    dispatch(setZoneSelected(''))
  }
}

export {
  findProviderByRut,
  getPeriodHistory,
  fillBasesList,
  getVendorEmail,
  changeProviderEmail,
  resendSettlement,
  fillProviderSummaryItems,
  removeZoneFilters
}
