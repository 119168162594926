import {
  IAction,
  IDiscount,
  IFilters
} from './discount-prioritization-interfaces'

const singleKey = '@PRIORITY'
const FILL_FILTERS = `${singleKey}/FILL_FILTERS`
const SET_FETCHING = `${singleKey}/SET_FETCHING`
const FILL_DISCOUNT_LIST = `${singleKey}/FILL_DISCOUNT_LIST`
const SET_SUCCESS_MODAL = `${singleKey}/SET_SUCCESS_MODAL`

const fillFilters = (data: IFilters): IAction => {
  return { type: FILL_FILTERS, payload: data }
}

const setFetching = (data: boolean): IAction => {
  return { type: SET_FETCHING, payload: data }
}

const fillDiscountList = (data: IDiscount[]): IAction => {
  return { type: FILL_DISCOUNT_LIST, payload: data }
}

const setSuccessModal = (data: boolean): IAction => {
  return { type: SET_SUCCESS_MODAL, payload: data }
}

export {
  FILL_FILTERS,
  SET_FETCHING,
  FILL_DISCOUNT_LIST,
  SET_SUCCESS_MODAL,
  fillFilters,
  setFetching,
  fillDiscountList,
  setSuccessModal
}
