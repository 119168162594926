import { IAction, IItemsExtractState, IZone } from './items-extract-interfaces'
import {
  FILL_BASES,
  FILL_BASES_OPTIONS,
  FILL_FILTERS,
  FILL_LIST_ITEMS,
  GET_SUMMARY_ITEMS,
  SET_FETCHING,
  SET_NAME_BASES_LIST,
  SET_ZONE_SEL
} from './items-extract-actions'

export const initialState: IItemsExtractState = {
  fetching: false,
  zoneList: [],
  basesList: [],
  filters: {
    mes: 0,
    anio: 0,
    base: ''
  },
  basesListOptions: [{ text: 'Todas las bases', value: '' }],
  nameBasesList: [],
  summaryItems: [],
  zoneSelected: ''
}
const ItemsExtractReducer = (
  state = initialState,
  action: IAction
): IItemsExtractState => {
  switch (action.type) {
    case FILL_LIST_ITEMS:
      let payload = action.payload
      if (state.zoneSelected.length > 0) {
        payload = payload.filter(
          (item: IZone) => item.zona === state.zoneSelected
        )
      }
      return { ...state, zoneList: payload }
    case FILL_BASES:
      return { ...state, basesList: action.payload }
    case FILL_BASES_OPTIONS:
      return { ...state, basesListOptions: action.payload }
    case FILL_FILTERS:
      return { ...state, filters: action.payload }
    case SET_ZONE_SEL:
      return { ...state, zoneSelected: action.payload }
    case SET_NAME_BASES_LIST:
      return { ...state, nameBasesList: action.payload }
    case GET_SUMMARY_ITEMS:
      return { ...state, summaryItems: action.payload }
    case SET_FETCHING:
      return { ...state, fetching: action.payload }
    default:
      return state
  }
}

export default ItemsExtractReducer
