const formatMonth = (month: string, year: string) => {
  const period = new Date(Number(year), Number(month) - 1, 1, 0, 0, 0)
  const format = period.toLocaleDateString('es-ES', { month: 'long' })
  const monthName = format
    .substring(0, 1)
    .toUpperCase()
    .concat(format.substring(1))
  return monthName
}

export default formatMonth
