import { SHOW_SENDING_ERROR } from './error-sending-actions'

export interface IErrorSendingState {
  showAlert: boolean
}

type Actions = { type: string; payload: string }

const initialState = {
  showAlert: true
}

const ErrorSendingReducer = (state = initialState, action: Actions) => {
  if (action.type === SHOW_SENDING_ERROR) {
    return { ...state, showAlert: action.payload }
  } else {
    return state
  }
}

export { ErrorSendingReducer }
