import PeriodHistoryService from './period-history-service'
import {
  fillBases,
  fillFilters,
  fillPeriodHistoryList,
  fillSummary,
  getPeriodList,
  setFetching,
  getSummaryItems
} from './period-history-actions'
import mapOrder from '@utilities/map-order'
import {
  setNameBasesList,
  setZoneSelected
} from '@features/period-history/period-history-actions'
import { fillBasesOptions } from '@features/items-extract/items-extract-actions'
import { initialState } from './period-history-reducer'
import { emptyList, zoneOrder } from '@constants/index'
import { ICollectionItems } from './period-history-interfaces'
import { AppThunk } from '@commons/thunk-types'
import saveAs from 'file-saver'

const fillAllData = (): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    PeriodHistoryService.getPeriodHistory(getState().user.jwtoken)
      .then(response => {
        if (response.status === 200) {
          dispatch(getPeriodList(response.data))
          const { estado, ...filters } = response.data[0]
          dispatch(fillFilters({ ...filters, base: '' }))
          dispatch(fillDataList({ summaryData: true }))
        }
      })
      .catch(error => {
        setFetching(false)
        console.error('Error trying to get data', error)
      })
  }
}

const fillDataList = ({ summaryData }: { summaryData: boolean }): AppThunk => {
  return (dispatch, getState) => {
    dispatch(setFetching(true))
    const { periodHistory } = getState()
    PeriodHistoryService.getDetails(
      { ...periodHistory.filters },
      getState().user.jwtoken
    )
      .then(response => {
        if (response.status === 200) {
          dispatch(
            fillPeriodHistoryList(mapOrder(response.data, zoneOrder, 'zona'))
          )
          if (summaryData) {
            dispatch(fillSummaryData())
          }
        } else {
          const { base } = periodHistory.filters
          dispatch(
            fillPeriodHistoryList(
              mapOrder(base ? emptyList : [], zoneOrder, 'zona')
            )
          )
        }
      })
      .catch(error => {
        dispatch(setFetching(false))
        console.error('Error trying to get data', error)
      })
      .finally(() => {
        if (!summaryData) {
          dispatch(setFetching(false))
        }
      })
  }
}

const fillBasesList = (): AppThunk => {
  return (dispatch, getState) => {
    PeriodHistoryService.getBases(getState().user.jwtoken)
      .then(response => {
        if (response.status === 200) {
          dispatch(fillBases(response.data))
        }
      })
      .catch(error => console.error('Error trying to get data', error))
  }
}

const fillSummaryData = (): AppThunk => {
  return (dispatch, getState) => {
    const { periodHistory } = getState()
    PeriodHistoryService.getPeriodSummary(
      { ...periodHistory.filters },
      getState().user.jwtoken,
      periodHistory.nameBasesList
    )
      .then(response => {
        if (response.status === 200) {
          dispatch(fillSummary(response.data))
          dispatch(fillSummaryItems(periodHistory.zoneSelected))
        }
        if (response.status === 204) {
          dispatch(fillSummary(initialState.summaryData))
          dispatch(fillSummaryItems())
        }
      })
      .catch(error => {
        dispatch(setFetching(false))
        console.error('Error trying to get data', error)
      })
  }
}

const fillSummaryItems = (zone?: string): AppThunk => {
  return (dispatch, getState) => {
    const { periodHistory } = getState()
    PeriodHistoryService.getSummaryItems(
      { ...periodHistory.filters },
      getState().user.jwtoken
    )
      .then(response => {
        if (response.status === 200) {
          if (!zone) zone = 'Todas'
          const summaryItems = response.data
          const items = summaryItems.filter(
            (item: ICollectionItems) => item.zona === zone
          )
          if (items.length === 0) {
            dispatch(getSummaryItems([]))
            return
          }
          dispatch(getSummaryItems(items[0].items))
          return
        }
        if (response.status === 204) {
          dispatch(getSummaryItems(initialState.summaryItems))
        }
      })
      .catch(error => console.error('Error trying to get data', error))
      .finally(() => dispatch(setFetching(false)))
  }
}

const cleanFilters = (): AppThunk => {
  return dispatch => {
    dispatch(fillFilters(initialState.filters))
  }
}

const downloadHesInfo = (): AppThunk => {
  return async (dispatch, getState) => {
    try {
      dispatch(setFetching(true))
      const { periodHistory } = getState()
      const result = await PeriodHistoryService.downloadHesInfo(
        { ...periodHistory.filters },
        getState().user.jwtoken
      )
      if (result.status === 200) {
        const blob = await result.blob()
        saveAs(blob, 'carga-hes-liquidaciones-info.xlsx')
        dispatch(setFetching(false))
        return
      }
      dispatch(setFetching(false))
    } catch (e) {
      dispatch(setFetching(false))
    }
  }
}

const removeZoneFilters = (): AppThunk => {
  return dispatch => {
    dispatch(setZoneSelected(''))
    dispatch(setNameBasesList([]))
    dispatch(fillBasesOptions(initialState.basesListOptions))
  }
}

export {
  fillDataList,
  fillBasesList,
  fillSummaryData,
  fillAllData,
  // fillDataFilter,
  cleanFilters,
  downloadHesInfo,
  // getPeriodHistory,
  removeZoneFilters
}
