import { routes } from '@constants/index'
import * as React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'

interface PublicRouteProps extends RouteProps {
  component: any
  isSignedIn: boolean
}

const PublicRoute = (props: PublicRouteProps) => {
  const { component: Component, isSignedIn, ...rest } = props

  return (
    <Route
      {...rest}
      render={routeProps =>
        !isSignedIn ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: routes.costExtract,
              state: { from: routeProps.location }
            }}
          />
        )
      }
    />
  )
}

export default PublicRoute
