import {
  FILL_BASES,
  FILL_BASES_OPTIONS,
  FILL_LIST,
  FILL_SUMMARY,
  FILL_FILTERS,
  SET_ZONE_SEL,
  SET_FETCHING,
  SET_NAME_BASES_LIST,
  GET_SUMMARY_ITEMS
} from './cost-extract-actions'
import { IAction, ICostExtractState, IZone } from './cost-extract-interfaces'

export const initialState: ICostExtractState = {
  fetching: false,
  zoneList: [],
  basesList: [],
  filters: {
    mes: 0,
    anio: 0,
    base: ''
  },
  summaryData: {
    montoTotal: 0,
    porcentajeInformados: 0,
    porcentajeCalculados: 0,
    porcentajeAprobados: 0,
    totalAprobados: 0,
    totalCalculados: 0,
    totalInformados: 0,
    fechaActualizacion: '',
    notificaciones: {
      bases: []
    }
  },
  basesListOptions: [{ text: 'Todas las bases', value: '' }],
  nameBasesList: [],
  summaryItems: [],
  zoneSelected: ''
}
const CostExtractReducer = (
  state = initialState,
  action: IAction
): ICostExtractState => {
  switch (action.type) {
    case FILL_LIST:
      let payload = action.payload
      if (state.zoneSelected.length > 0) {
        payload = payload.filter(
          (item: IZone) => item.zona === state.zoneSelected
        )
      }
      return { ...state, zoneList: payload }
    case FILL_SUMMARY:
      return { ...state, summaryData: action.payload }
    case FILL_BASES:
      return { ...state, basesList: action.payload }
    case FILL_BASES_OPTIONS:
      return { ...state, basesListOptions: action.payload }
    case FILL_FILTERS:
      return { ...state, filters: action.payload }
    case SET_ZONE_SEL:
      return { ...state, zoneSelected: action.payload }
    case SET_NAME_BASES_LIST:
      return { ...state, nameBasesList: action.payload }
    case GET_SUMMARY_ITEMS:
      return { ...state, summaryItems: action.payload }
    case SET_FETCHING:
      return { ...state, fetching: action.payload }
    default:
      return state
  }
}

export default CostExtractReducer
