import { IAction, IFilters, ITransferData } from './transfer-history-interfaces'

const singleKey = '@TRANSFER-HISTORY'
const FILL_FILTERS = `${singleKey}/FILL_FILTERS`
const SET_FETCHING = `${singleKey}/SET_FETCHING`
const SET_HISTORY_DATA = `${singleKey}/SET_DATA`
const SET_PERIOD_LIST = `${singleKey}/SET_PERIOD_LIST`
const SET_PAGE = `${singleKey}/SET_PAGE`
const SET_IN_PROGRESS = `${singleKey}/SET_IN_PROGRESS`
const SHOW_CONFIRM_REVERSE = `${singleKey}/SHOW_CONFIRM_REVERSE`
const SET_REVERSE_ID = `${singleKey}/SET_REVERSE_ID`
const SHOW_SUCCESS_MODAL = `${singleKey}/SHOW_SUCCESS_MODAL`

const fillFilters = (data: IFilters): IAction => {
  return { type: FILL_FILTERS, payload: data }
}

const setFetching = (data: boolean): IAction => {
  return { type: SET_FETCHING, payload: data }
}

const setTransferHistoryData = (data: ITransferData): IAction => {
  return { type: SET_HISTORY_DATA, payload: data }
}

const setPeriodList = (data: any): IAction => {
  return { type: SET_PERIOD_LIST, payload: data }
}

const setPage = (data: number): IAction => {
  return { type: SET_PAGE, payload: data }
}

const setInProgress = (data: IFilters): IAction => {
  return { type: SET_IN_PROGRESS, payload: data }
}

const showConfirmReverse = (data: boolean): IAction => {
  return { type: SHOW_CONFIRM_REVERSE, payload: data }
}

const setReverseId = (data: string): IAction => {
  return { type: SET_REVERSE_ID, payload: data }
}

const setSuccessModal = (data: boolean): IAction => {
  return { type: SHOW_SUCCESS_MODAL, payload: data }
}

export {
  FILL_FILTERS,
  SET_FETCHING,
  SET_HISTORY_DATA,
  SET_PERIOD_LIST,
  SET_PAGE,
  SET_IN_PROGRESS,
  SHOW_CONFIRM_REVERSE,
  SET_REVERSE_ID,
  SHOW_SUCCESS_MODAL,
  fillFilters,
  setFetching,
  setTransferHistoryData,
  setPeriodList,
  setPage,
  setInProgress,
  showConfirmReverse,
  setReverseId,
  setSuccessModal
}
