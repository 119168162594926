import BaseService from '@core/request-service'

class EditTemplateService extends BaseService {
  static uploadFileHtml(
    params: any,
    jwt: string
  ): Promise<{ status: number; data: any }> {
    return super.post(`/email/templates/upload`, params, jwt)
  }

  static async downloadTemplateHtml(jwt: string): Promise<Response> {
    const endpoint = `${process.env.REACT_APP_API}/email/templates/download`
    let headers = new Headers()
    headers.append('Authorization', `Bearer ${jwt}`)
		headers.append('apikey', `${process.env.REACT_APP_KONG_API_KEY}`)
    const response = await fetch(endpoint, {
      method: 'GET',
      headers
    })
    return response
  }

  static selectTemplate(status: string, jwt: string): Promise<any> {
    return super.patch(`/email/templates/${status}`, jwt)
  }

  static getTemplateStatus(
    jwt: string
  ): Promise<{ status: number; data: any }> {
    return super.get('/email/templates/status', jwt)
  }
}

export default EditTemplateService
