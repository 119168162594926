import formatDate from 'intl-dateformat'

const localeApp = String(window.navigator.language).substring(0, 2)

const FormatStringDateTime = (strDate: string, mask: string): string => {
  const regex = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+/
  if (!regex.test(strDate)) return ''
  const dateAux = new Date(strDate)
  return formatDate(dateAux, mask, { locale: localeApp })
}

export default FormatStringDateTime
