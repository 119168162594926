import BaseService from '@core/request-service'
import { IFilters } from './preview-interfaces'
import { createQueryString } from '@utilities/index'

class PreviewService extends BaseService {
  static getDetails(
    filters: IFilters,
    jwt: string
  ): Promise<{ status: number; data: any }> {
    return super.get(
      `/liquidaciones/preview?${createQueryString(filters)}`,
      jwt
    )
  }

  static sendPreviewFile(
    filters: IFilters,
    params: any,
    jwt: string
  ): Promise<{ status: number; data: any }> {
    return super.post(
      `/liquidaciones/upload?${createQueryString(filters)}`,
      params,
      jwt
    )
  }

  static async downloadPreviewTemplate(
    filters: IFilters,
    jwt: string
  ): Promise<Response> {
    const endpoint = `${
      process.env.REACT_APP_API
    }/liquidaciones/template/download?${createQueryString(filters)}`
    let headers = new Headers()
    headers.append('Authorization', `Bearer ${jwt}`)
		headers.append('apikey', `${process.env.REACT_APP_KONG_API_KEY}`)
    const response = await fetch(endpoint, {
      method: 'GET',
			headers
    })
    return response
  }

  static async downloadPreviewInfo(
    filters: IFilters,
    jwt: string
  ): Promise<Response> {
    const endpoint = `${
      process.env.REACT_APP_API
    }/liquidaciones/download?${createQueryString(filters)}`
    let headers = new Headers()
    headers.append('Authorization', `Bearer ${jwt}`)
		headers.append('apikey', `${process.env.REACT_APP_KONG_API_KEY}`)
    const response = await fetch(endpoint, {
      method: 'GET',
			headers
    })
    return response
  }

  static getBases(jwt: string): Promise<{ status: number; data: any }> {
    return super.get('/bases', jwt)
  }

  static getFilters(jwt: string): Promise<any> {
    return super.get('/periodos/in-progress', jwt)
  }

  static getTotalWarning(
    filters: IFilters,
    jwt: string
  ): Promise<{ status: number; data: any }> {
    return super.get(
      `/liquidaciones/warning?${createQueryString(filters)}`,
      jwt
    )
  }
}

export default PreviewService
