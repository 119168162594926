import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translations_es from '@translations/es.json'
import translations_en from '@translations/en.json'

const resources = {
  es: {
    translation: translations_es
  },
  en: {
    translation: translations_en
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'es',

  keySeparator: false,

  interpolation: {
    escapeValue: false
  }
})

export default i18n
