import {
  SET_FETCHING,
  SET_SUCCESS_MODAL,
  SET_TEMPLATE_STATUS
} from './edit-template-actions'
import { IAction, IEditTemplateState } from './edit-template-interfaces'

const initialState = {
  fetching: false,
  successModal: false,
  templateStatus: {
    name: '',
    enabled: undefined
  }
}

const EditTemplateReducer = (
  state = initialState,
  action: IAction
): IEditTemplateState => {
  switch (action.type) {
    case SET_FETCHING:
      return { ...state, fetching: action.payload }
    case SET_SUCCESS_MODAL:
      return { ...state, successModal: action.payload }
    case SET_TEMPLATE_STATUS:
      return { ...state, templateStatus: action.payload }
    default:
      return state
  }
}

export default EditTemplateReducer
